import {
  cloneElement,
  FC,
  isValidElement,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  VideocamOutlined,
  Newspaper,
  MicNone,
  Language,
  PhotoOutlined,
  Photo,
  Mic,
  Videocam,
  NewspaperOutlined,
} from '@mui/icons-material';

import { MaterialContentContainer } from './tab-news-content/material-content/material-content.container';
import { VideoContentContainer } from './tab-news-content/video-content/video-content.container';
import { PodcastContentContainer } from './tab-news-content/podcast-content/podcast-content.container';
import { AllContentContainer } from './tab-news-content/all-content/all-content.container';
import { GalleryContentContainer } from './tab-news-content/gallery-content/gallery-content.container';

import { GovNews, GovNewsProps } from './gov-news';
import {
  IconPositionEnum,
  TabConfig,
} from '../../../components/tabs-news/tabs-news';
import { Category, CategoryFilter } from '../../list/list.container';
import { getNewsCategories } from '../../../apis/categorys';

interface Tab extends TabConfig {
  iconSelected?: ReactElement;
}

type GovNewsContainerProps = {
  titleSection?: { title: string; subtitle: string };
  hasSeeAll?: boolean;
  hasFilter?: boolean;
  tabs?: Tab[];
};

export const GovNewsContainer: FC<GovNewsContainerProps> = ({
  titleSection = {
    title: 'Notícias',
    subtitle: 'Acompanhe tudo o que esta acontecendo no nosso Estado',
  },
  hasSeeAll = true,
  hasFilter = false,
  tabs,
}) => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [selectedCategories, setSelectedCategories] = useState<
    CategoryFilter[]
  >([]);
  const [categories, setCategories] = useState<Category[]>([]);

  const onCategorySelect = useCallback((selectedCategory: CategoryFilter) => {
    setSelectedCategories((prev) =>
      prev.some(({ id }) => id === selectedCategory.id)
        ? prev.filter(({ id }) => id !== selectedCategory.id)
        : [...prev, selectedCategory]
    );
  }, []);

  const fetchCategories = useCallback(async () => {
    try {
      const fetchedCategories = await getNewsCategories();
      setCategories(fetchedCategories);
    } catch (e) {
      console.log(e);
    }
  }, []);

  const onClickSearch = useCallback(
    (toggleDrawer: () => void) => () => {
      toggleDrawer();
    },
    []
  );

  const slugs = useMemo(
    () => selectedCategories.map(({ slug }) => slug),
    [selectedCategories]
  );

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  const props: GovNewsProps = useMemo(
    () => ({
      sliderSettings: {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplaySpeed: 8000,
        autoplay: true,
        cssEase: 'linear',
        nextArrow: <></>,
        prevArrow: <></>,
      },
      govPrograms: [
        { img: 'news-banner-01.png', alt: 'Programa Ronda do Bairro' },
        {
          img: 'news-banner-02.png',
          alt: 'Programa Vida Nova nas Grotas de São Paulo',
        },
        { img: 'news-banner-03.png', alt: 'Programa Cria Criança' },
      ].map(({ img, ...program }) => ({
        ...program,
        img: `${process.env.PUBLIC_URL}/images/gov-news/${img}`,
      })),
      tabsProps: {
        tabs: (
          tabs ?? [
            {
              title: 'Matérias',
              icon: <NewspaperOutlined />,
              iconSelected: <Newspaper />,
              content: <MaterialContentContainer />,
            },
            {
              title: 'Vídeos',
              icon: <VideocamOutlined />,
              iconSelected: <Videocam />,
              content: <VideoContentContainer />,
            },
            {
              title: 'Podcasts',
              icon: <MicNone />,
              iconSelected: <Mic />,
              content: <PodcastContentContainer />,
            },
            {
              title: 'Galeria',
              icon: <PhotoOutlined />,
              iconSelected: <Photo />,
              content: <GalleryContentContainer />,
            },
            {
              title: 'Tudo',
              icon: <Language />,
              iconSelected: <Language />,
              content: <AllContentContainer />,
            },
          ]
        ).map(({ icon, iconSelected, content, ...tab }, index) => ({
          iconPosition: IconPositionEnum.START,
          ...tab,
          icon: index === currentTabIndex ? iconSelected : icon,
          content: isValidElement(content)
            ? cloneElement(content, { ...content.props, slugs })
            : content,
        })),
        handleChange: (_, tabIndex) => {
          setCurrentTabIndex(tabIndex);
        },
        currentTabIndex,
        ...(hasFilter && {
          categories,
          onCategorySelect,
          selectedCategories,
          onClickSearch,
        }),
      },
      titleSection,
      hasSeeAll,
    }),
    [
      slugs,
      currentTabIndex,
      categories,
      hasSeeAll,
      titleSection,
      tabs,
      hasFilter,
      selectedCategories,
      onCategorySelect,
      onClickSearch,
    ]
  );

  return <GovNews {...props} />;
};
