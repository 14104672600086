import { FC } from 'react';

import { Pagination } from '../../../../../components';

import { BiggerCard, ContainerCards } from './style';
import { NewsCard } from '../../../../../components/cards/news-card';
import { NewsMediaItem } from '../all-content/all-content.container';
import { Clickable } from '../../../../../apis/news';

export interface MaterialItem
  extends Omit<NewsMediaItem, 'imageVideo'>,
    Clickable {
  text: string;
  image: string;
}

export interface MaterialContentProps {
  newsList: MaterialItem[];
  totalPages: number;
  handleChange: any;
  page: number;
}
export const MaterialContent: FC<MaterialContentProps> = ({
  newsList,
  page,
  totalPages,
  handleChange,
}) => (
  <>
    <ContainerCards size={newsList?.length ?? 0}>
      {newsList?.map((item, index) => {
        const CardComponent = index % 11 ? NewsCard : BiggerCard;
        return <CardComponent item={item} key={index} />;
      })}
    </ContainerCards>

    <Pagination count={totalPages} onChange={handleChange} page={page} />
  </>
);
