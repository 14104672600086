import { FC } from 'react';
import { Breadcrumb, BreadcrumbItem } from '../breadcrumb/breadcrumb';
import { ArrowBack } from '@mui/icons-material';
import { Container } from '../container/container';
import {
  ContainerTitle,
  Button,
  ContainerPageBanner,
  BackgroundContainer,
} from './style';

interface IPageBannerProps {
  bannerTitle: string;
  bannerImage?: string;
  color?: string;
  breadcrumbs?: BreadcrumbItem[];
}

export const PageBanner: FC<IPageBannerProps> = ({
  bannerTitle,
  bannerImage,
  breadcrumbs,
  color,
}) => (
  <BackgroundContainer>
    <ContainerPageBanner
      style={{
        backgroundImage: `url(${bannerImage})`,
        backgroundColor: `${color}`,
      }}
    >
      <Container className="xvia-page-banners-container">
        {breadcrumbs?.length && (
          <div className="xvia-catalog__breadcrumb_header">
            <Breadcrumb items={breadcrumbs} aria-label="breadcrumb" />
          </div>
        )}
        <ContainerTitle>
          <Button
            startIcon={<ArrowBack />}
            children=""
            className="xvia-page-banners-button"
            sx={{ padding: '0px', margin: '0px' }}
            onClick={() => window.history.back()}
          />
          <h2>{bannerTitle}</h2>
        </ContainerTitle>
      </Container>
    </ContainerPageBanner>
  </BackgroundContainer>
);
