import {
  FC,
  createElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { List, ListProps } from './list';
import { useWebgate } from '@xvia/webgate-connect';
import { getServerData } from '../../util/get-server-data';
import { Catalog, search } from '../../apis/search-catalog';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { ICategory, getCategorys } from '../../apis/categorys';
import { ROUTES } from '../../routes';

const striptags = require('striptags');
export interface Category {
  description: string;
  hidden: boolean;
  icon: string;
  id: string;
  name: string;
  orderIndex: number;
  slug: string;
}

export type CategoryFilter = Pick<Category, 'id' | 'name' | 'slug'>;

export const ListContainer: FC = () => {
  const { accessToken, serverData, portalConnect } = useWebgate();
  let history = useHistory();
  const location = useLocation();
  const service = useMemo(
    () => location.pathname.split('/search/')[1],
    [location.pathname]
  );

  let { category, query, orgao } =
    useParams<{ category: string; query: string; orgao: string }>();

  const [categoryList, setCategoryList] = useState<ICategory[]>(
    serverData?.categoryList || getServerData('categoryList', null)
  );
  const [catalogList, setCatalogList] = useState<any[]>(
    serverData?.catalogList || getServerData('catalogList', null)
  );
  const [categorySlugList, setCategorySlugList] = useState<string[]>([]);
  const [resultTotal, setResultTotal] = useState<number>();
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState<
    CategoryFilter[]
  >([]);

  const disposition: 'line' | 'card' = 'card';

  const serverSearch = useCallback(async () => {
    const params: Record<string, any> = {
      accessToken,
      from: page - 1,
      ...(service && { query: service }),
      ...(categorySlugList.length > 0 && { category: categorySlugList }),
    };

    try {
      const res = await search(params);
      setCatalogList(res);
      setResultTotal(res[0]?.resultTotal || 0);
    } catch (err) {
      console.error('Erro ao buscar serviços:', err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, resultTotal, service, categorySlugList]);

  const onFilterByCategory = useCallback(
    (selectedCategories: CategoryFilter[]) =>
      setCategorySlugList(selectedCategories.map(({ slug }) => slug)),
    [setCategorySlugList]
  );

  const getCategoryServer = useCallback(() => {
    getCategorys()
      .then(setCategoryList)
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const openLink = useCallback((link) => {
    if (link.indexOf('/app/') !== -1) {
      portalConnect?.redirectTo(link);
    } else {
      window.open(link, '_blank');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickCard = useCallback(
    (catalog: Catalog) => {
      const { slug } = catalog;

      history.push(`${ROUTES.SERVICE_LIST_PAGE}/${slug}`);
    },
    [history]
  );

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    if (typeof window !== 'undefined' && window.scrollY !== 0) {
      window.scrollTo(0, 0);
    }
    setPage(value);
  };

  const getTotalPages = (totalItems: number | undefined): number => {
    if (!totalItems) return 0;
    return Math.ceil(totalItems / 10);
  };

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const handleCheckboxChange = ({ id, name, slug }: CategoryFilter) => {
    setSelectedCategories((prev) =>
      prev.find((item) => item.id === id)
        ? prev.filter((item) => item.id !== id)
        : [...prev, { id, name, slug }]
    );
  };

  const handleFilterByCategory = () => {
    setOpen(false);
    onFilterByCategory(selectedCategories);
  };

  useEffect(() => {
    serverSearch();
    if (!categoryList) getCategoryServer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, category, orgao, page]);

  useEffect(() => {
    if (service) {
      serverSearch();
    }
  }, [service, serverSearch]);

  useEffect(() => {
    if (categorySlugList.length > 0) {
      serverSearch();
    }
  }, [categorySlugList, serverSearch]);

  const newsProps: ListProps = {
    list: catalogList?.map((catalog) => {
      return {
        ...catalog,
        description: striptags(catalog.description),
      } as Catalog;
    }),
    onClick: onClickCard,
    onClickFavourite: () => {},
    onClickOnlineService: openLink,
    disposition,
    resultTotal,
    page,
    handleChange,
    toggleDrawer,
    open,
    selectedCategories,
    handleCheckboxChange,
    handleFilterByCategory,
    categories: categoryList,
    totalPages: getTotalPages(resultTotal),
  };

  return createElement(List, newsProps);
};
