import { FC, ReactNode, ReactElement } from 'react';
import { StyledTabs, StyledTab, ContentContainer, Container } from './style';
import { FilterDrawer } from '../filter-drawer/filter-drawer';
import { Category, CategoryFilter } from '../../modules/list/list.container';

export enum IconPositionEnum {
  TOP = 'top',
  START = 'start',
  END = 'end',
  BOTTOM = 'bottom',
}

export interface TabConfig {
  title: string;
  icon?: ReactElement;
  iconPosition?: IconPositionEnum;
  content: ReactNode;
}

export interface TabsProps {
  tabs: TabConfig[];
  currentTabIndex: number;
  handleChange: (_: any, newValue: number) => void;
  categories?: Category[];
  selectedCategories?: CategoryFilter[];
  onCategorySelect?: (value: CategoryFilter) => void;
  onClickSearch?: (toggleDrawer: () => void) => () => void;
}

export const TabsNews: FC<TabsProps> = ({
  tabs,
  currentTabIndex,
  handleChange,
  categories,
  selectedCategories,
  onCategorySelect,
  onClickSearch,
}) => (
  <>
    <Container hasFilter={!!categories?.length}>
      <StyledTabs
        value={currentTabIndex}
        onChange={handleChange}
        aria-label="simple tabs"
        {...(!categories?.length && { centered: true })}
        TabIndicatorProps={{
          children: <span className="MuiTabs-indicatorSpan" />,
        }}
      >
        {tabs.map(({ title, icon, iconPosition }, index) => (
          <StyledTab
            key={index}
            disableRipple
            label={title}
            id={`simple-tab-${index}`}
            aria-controls={`simple-tabpanel-${index}`}
            {...(icon && { icon })}
            {...(iconPosition && { iconPosition })}
          />
        ))}
      </StyledTabs>
      {categories?.length && onCategorySelect && onClickSearch && (
        <FilterDrawer
          onClickSearch={onClickSearch}
          onCategorySelect={onCategorySelect}
          categoryList={categories}
          selectedCategories={selectedCategories}
        />
      )}
    </Container>
    {tabs.map(
      ({ content }, index) =>
        currentTabIndex === index && (
          <ContentContainer
            role="tabpanel"
            hidden={currentTabIndex !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            key={index}
          >
            {content}
          </ContentContainer>
        )
    )}
  </>
);
