import React, { FC } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { Pagination } from '../../../../../components';

import TodayIcon from '@mui/icons-material/Today';
import CategoryIcon from '@mui/icons-material/Category';

import { mathIconCategory } from '../../../../../util/matchAplicationIcons';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import {
  Box,
  BoxCardIconInfo,
  BoxChevronCard,
  BoxImage,
  BoxText,
  Card,
  CardContent,
  CardIconInfo,
  GridContainerCards,
  Text,
} from './style';
import { NewsMediaItem } from '../all-content/all-content.container';

interface IVideoContentProps {
  directionCardColumns: 'row' | 'column';
  newsList: NewsMediaItem[];
  page: number;
  totalPages: number;
  handleChange: (event: React.ChangeEvent<unknown>, value: number) => void;
}

export const VideoContent: FC<IVideoContentProps> = ({
  directionCardColumns = 'row',
  newsList,
  page,
  totalPages,
  handleChange,
}) => (
  <Box sx={{ flexGrow: 1 }}>
    <Grid
      container
      spacing={2}
      direction={directionCardColumns}
      sx={{ display: { xs: 'block', md: 'flex' } }}
    >
      {newsList?.map((item, index) => (
        <GridContainerCards item xs={6} key={index}>
          <Card style={{ minHeight: '180px' }} onClick={item.handleClick}>
            <CardContent>
              <BoxImage>
                <img src={item.imageVideo} alt={item.imageAlt} />
              </BoxImage>
              <BoxText>
                <BoxCardIconInfo>
                  <CardIconInfo>
                    <TodayIcon />
                    <Typography variant="body2">{item.date}</Typography>
                  </CardIconInfo>
                  <CardIconInfo>
                    {mathIconCategory[item.category] || <CategoryIcon />}
                    <Typography variant="body2">{item.category}</Typography>
                  </CardIconInfo>
                </BoxCardIconInfo>

                <Text>{item.title}</Text>
              </BoxText>
              <BoxChevronCard>
                <ChevronRightIcon />
              </BoxChevronCard>
            </CardContent>
          </Card>
        </GridContainerCards>
      ))}
    </Grid>

    <Pagination count={totalPages} onChange={handleChange} page={page} />
  </Box>
);
