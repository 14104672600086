import { FC } from 'react';
import { PodcastContent } from './podcast-content';
import { GenericContentContainer } from '../generic-content/container';
import { getPodcastNews } from '../../../../../apis/news';

interface PodcastContentContainerProps {
  reorganizeVideoNews?: boolean;
  directionCardColumns?: 'row' | 'column';
  limit?: number;
  slugs?: string[];
}

export const PodcastContentContainer: FC<PodcastContentContainerProps> = ({
  reorganizeVideoNews,
  directionCardColumns,
  limit = 4,
  slugs,
}) => (
  <GenericContentContainer
    fetch={getPodcastNews(slugs)}
    route="podcast"
    limit={limit}
    reorganizeItems={reorganizeVideoNews}
    directionCardColumns={directionCardColumns}
    NewsComponent={PodcastContent}
  />
);
