import { FC, useMemo } from 'react';
import { DynamicNews } from './dynamic-news';

import { VideoNewsContainer } from './video-news/video-news.container';
import { PageBanner } from '../../components/page-banner/page-banner';
import { PodcastNewsContainer } from './podcast-news/podcast-news.container';

import { Element } from 'react-scroll';
import { SimilarDynamicNewsContainer } from './similar-dynamic-news/similar-dynamic-news.container';
import { MatterNews } from './matter-news/matter-news';

export enum DynamicNewsTypeEnum {
  PODCAST = 'podcast',
  VIDEO = 'video',
  MATERIAL = 'materia',
}

export interface IDynamicNewsTypeProps {
  newsType: DynamicNewsTypeEnum;
}

const breadcrumbs = {
  materia: [
    { name: 'Notícias', path: `` },
    {
      id: 'materia',
      title: 'Ler matéria',
      name: 'Ler matéria',
      path: `/noticias`,
    },
  ],
  podcast: [
    { name: 'Notícias', path: `` },
    {
      id: 'podcast',
      title: 'Escutar Podcast',
      name: 'Escutar Podcast',
      path: `/noticias`,
    },
  ],
  video: [
    { name: 'Notícias', path: `` },
    {
      id: 'video',
      title: 'Assistir Vídeo',
      name: 'Assistir Vídeo',
      path: `/noticias`,
    },
  ],
};
const apiContent = {
  authorRead: 'Rafaela Bastos',
  authorVideo: 'Yuri Mendes',
  date: 'há 12 minutos',

  origin: 'SEDUC - Secretaria de Estado da Educação',
  category: 'Categoria: Educação',
  textContent: `
          <h3>Provas da Olimpíada Brasileira de Matemática das Escolas Públicas são aplicadas nos 102 municípios</h3>
          <p>Na última semana, estudantes de Alagoas participaram da primeira fase da Olimpíada Brasileira de Matemática das Escolas Públicas (OBMEP), que se estendeu por todos os 102 municípios do estado. O evento, que é um dos maiores concursos do país voltado para o desenvolvimento do raciocínio lógico e matemático de alunos do ensino fundamental e médio, contou com a participação de milhares de jovens alagoanos em busca de destaque nas disciplinas de exatas.
          <br/>
          <br/>
          A OBMEP tem como objetivo principal estimular o interesse pela matemática, uma disciplina muitas vezes vista como desafiadora, e descobrir talentos em todo o Brasil. Em Alagoas, a adesão à competição tem sido crescente, com o envolvimento de diversas escolas públicas, tanto na capital quanto no interior.
          </p>
        `,
};

const podcastApiResponse = {
  authorRead: 'Rafaela Bastos',
  authorAudio: 'Yuri Mendes',
  date: 'há 12 minutos',

  origin: 'SEDUC - Secretaria de Estado da Educação',
  category: 'Categoria: Educação',
  textContent: `
          <h3>Provas da Olimpíada Brasileira de Matemática das Escolas Públicas são aplicadas nos 102 municípios</h3>
          <p>Na última semana, estudantes de Alagoas participaram da primeira fase da Olimpíada Brasileira de Matemática das Escolas Públicas (OBMEP), que se estendeu por todos os 102 municípios do estado. O evento, que é um dos maiores concursos do país voltado para o desenvolvimento do raciocínio lógico e matemático de alunos do ensino fundamental e médio, contou com a participação de milhares de jovens alagoanos em busca de destaque nas disciplinas de exatas.
          <br/>
          <br/>
          A OBMEP tem como objetivo principal estimular o interesse pela matemática, uma disciplina muitas vezes vista como desafiadora, e descobrir talentos em todo o Brasil. Em Alagoas, a adesão à competição tem sido crescente, com o envolvimento de diversas escolas públicas, tanto na capital quanto no interior.
          </p>
        `,
};

const matterApiReposnse = {
  origin: 'SEDUC - Secretaria de Estado da Educação',
  img: 'img',
  category: 'Categoria: Educação',
  date: 'há 12 minutos',
  authorRead: 'Rafaela Bastos',
  authorPicture: 'Yuri Mendes',
  newsContent: `
      <h3>Provas da Olimpíada Brasileira de Matemática das Escolas Públicas são aplicadas nos 102 municípios</h3>
      <p>Na última semana, estudantes de Alagoas participaram da primeira fase da Olimpíada Brasileira de Matemática das Escolas Públicas (OBMEP), que se estendeu por todos os 102 municípios do estado. O evento, que é um dos maiores concursos do país voltado para o desenvolvimento do raciocínio lógico e matemático de alunos do ensino fundamental e médio, contou com a participação de milhares de jovens alagoanos em busca de destaque nas disciplinas de exatas.
      <br/>
      <br/>
      A OBMEP tem como objetivo principal estimular o interesse pela matemática, uma disciplina muitas vezes vista como desafiadora, e descobrir talentos em todo o Brasil. Em Alagoas, a adesão à competição tem sido crescente, com o envolvimento de diversas escolas públicas, tanto na capital quanto no interior.
      </p>

      <h3>Um estímulo à educação de qualidade</h3>
      <p>A aplicação das provas em todos os municípios alagoanos reforça o compromisso do estado com a promoção de uma educação de qualidade e equitativa. Além de identificar jovens talentos, a OBMEP oferece oportunidades de inclusão, uma vez que premia alunos e escolas em diversas categorias, incentivando o desenvolvimento acadêmico mesmo em regiões mais afastadas ou carentes de recursos educacionais.
      <br/>
      <br/>
      Professores e diretores das escolas públicas ressaltam a importância do evento não apenas para estimular os alunos, mas também para criar uma cultura de valorização da matemática, que pode abrir portas para diversas áreas do conhecimento e oportunidades profissionais no futuro.
      </p>

      <h3>O caminho até a medalha</h3>
      <p>Os estudantes que se destacarem nessa primeira fase seguirão para a segunda etapa da competição, onde enfrentarão provas mais desafiadoras. Aqueles que conquistarem as melhores colocações poderão ser premiados com medalhas de ouro, prata e bronze, além de bolsas de iniciação científica, o que pode representar uma mudança significativa em suas trajetórias acadêmicas e profissionais.
      <br/>
      <br/>
      A OBMEP não se limita apenas à competição. A olimpíada também oferece programas de formação para professores, além de material didático gratuito e capacitação para escolas, reforçando o impacto positivo na educação pública do país.
      <br/>
      <br/>
      Com o engajamento de todos os 102 municípios, Alagoas mostra que está comprometida com a educação matemática, proporcionando aos seus estudantes a chance de brilhar em um dos mais importantes concursos acadêmicos do Brasil.
      </p>
    `,
};

export const DynamicNewsContainer: FC<IDynamicNewsTypeProps> = ({
  newsType,
}) => {
  const pageBannerTitle = useMemo(
    () =>
      breadcrumbs[newsType].find((item) => item.id === newsType)?.title ?? '',
    [newsType]
  );

  const pageBannerBreadcrumb = useMemo(() => breadcrumbs[newsType], [newsType]);

  return (
    <>
      <PageBanner
        bannerTitle={pageBannerTitle}
        breadcrumbs={pageBannerBreadcrumb}
        color="var(--xvia-color-primary-ligth)"
      />
      <div className="xvia-container">
        {
          {
            video: (
              <>
                <VideoNewsContainer {...apiContent} />
                <DynamicNews {...apiContent} />
              </>
            ),
            podcast: (
              <>
                <PodcastNewsContainer {...podcastApiResponse} />
                <DynamicNews {...apiContent} />
              </>
            ),
            materia: <MatterNews {...matterApiReposnse} />,
          }[newsType]
        }
      </div>
      <Element name="DynamicSimilarNews">
        <SimilarDynamicNewsContainer newsType={newsType} />
      </Element>
    </>
  );
};
