import { ArrowForwardRounded, Today, Category } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { FC } from 'react';
import { MaterialItem } from '../../../modules/home/gov-news/tab-news-content/material-content/material-content';
import {
  Button,
  Card,
  CardActions,
  CardImg,
  CardContainerInfo,
  CardIconsInfo,
  CardNotice,
  CardText,
  CardTitle,
  MetadataDetailsContainer,
} from './style';
import { mathIconCategory } from '../../../util/matchAplicationIcons';

export type NewsCardProps = {
  item: MaterialItem;
  className?: string;
};

export const NewsCard: FC<NewsCardProps> = ({ item, className }) => (
  <Card component="a" onClick={item.handleClick} className={className}>
    <CardImg src={item.image} alt={item.imageAlt} />
    <CardContainerInfo>
      <MetadataDetailsContainer
        direction={{ xs: 'column', sm: 'row' }}
        spacing={{ xs: 1, sm: 2, md: 2 }}
      >
        <CardIconsInfo>
          <Today />
          <Typography variant="body2">{item.date}</Typography>
        </CardIconsInfo>
        <CardIconsInfo>
          {mathIconCategory[item.category] || <Category />}
          <Typography variant="body2">{item.category}</Typography>
        </CardIconsInfo>
      </MetadataDetailsContainer>

      <CardNotice className="CardNotice">
        <CardTitle className="CardTitle">{item.title}</CardTitle>
        <CardText>{item.text}</CardText>
      </CardNotice>

      <CardActions>
        <Button className="box">
          Ler completa
          <ArrowForwardRounded />
        </Button>
      </CardActions>
    </CardContainerInfo>
  </Card>
);
