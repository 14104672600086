import * as React from 'react';
import { ContainerPagination, List, PaginationButton } from './style';
import {
  ArrowCircleLeft,
  ArrowCircleLeftOutlined,
  ArrowCircleRight,
  ArrowCircleRightOutlined,
} from '@mui/icons-material';

import usePagination from '@mui/material/usePagination';

interface IPaginationProps {
  count: number;
  onChange: (event: React.ChangeEvent<unknown>, value: number) => void;
  page: number;
}

export default function UsePagination({
  count,
  onChange,
  page,
}: IPaginationProps) {
  const { items } = usePagination({
    count,
    onChange,
    page,
  });
  const [hoveredButton, setHoveredButton] = React.useState<string | null>(null);

  return (
    <ContainerPagination>
      <nav>
        <List>
          {items.map(({ page, type, selected, ...item }, index) => {
            let children = null;

            const isHovered = hoveredButton === type;

            if (type === 'start-ellipsis' || type === 'end-ellipsis') {
              children = '…';
            } else if (type === 'page') {
              children = (
                <PaginationButton type="button" {...item}>
                  {page}
                </PaginationButton>
              );
            } else if (type === 'previous') {
              children = (
                <PaginationButton
                  type="button"
                  {...item}
                  onMouseEnter={() => setHoveredButton('previous')}
                  onMouseLeave={() => setHoveredButton(null)}
                >
                  {isHovered ? (
                    <ArrowCircleLeft />
                  ) : (
                    <ArrowCircleLeftOutlined />
                  )}
                </PaginationButton>
              );
            } else if (type === 'next') {
              children = (
                <PaginationButton
                  type="button"
                  {...item}
                  onMouseEnter={() => setHoveredButton('next')}
                  onMouseLeave={() => setHoveredButton(null)}
                >
                  {isHovered ? (
                    <ArrowCircleRight />
                  ) : (
                    <ArrowCircleRightOutlined />
                  )}
                </PaginationButton>
              );
            }

            return <li key={index}>{children}</li>;
          })}
        </List>
      </nav>
    </ContainerPagination>
  );
}
