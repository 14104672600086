import React, { FC, Fragment } from 'react';
import Grid from '@mui/material/Grid';

import { Pagination } from '../../../../../components';

import TodayIcon from '@mui/icons-material/Today';
import CategoryIcon from '@mui/icons-material/Category';

import { mathIconCategory } from '../../../../../util/matchAplicationIcons';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MicIcon from '@mui/icons-material/Mic';
import {
  Box,
  BoxCardIconInfo,
  BoxChevronCard,
  BoxImage,
  BoxText,
  Card,
  CardContent,
  CardIconInfo,
  GridContainerCards,
  Text,
} from './style';
import { NewsItem } from '../all-content/all-content.container';
import { Clickable } from '../../../../../apis/news';

interface IPodcastContentProps {
  directionCardColumns: 'row' | 'column';
  newsList: (NewsItem & Clickable)[];
  page: number;
  totalPages: number;
  handleChange: (event: React.ChangeEvent<unknown>, value: number) => void;
}

export const PodcastContent: FC<IPodcastContentProps> = ({
  directionCardColumns,
  newsList,
  page,
  totalPages,
  handleChange,
}) => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid
        container
        spacing={2}
        direction={directionCardColumns ? directionCardColumns : 'row'}
        sx={{
          display: { xs: 'block', md: 'flex' },
        }}
      >
        {newsList.length && (
          <>
            {newsList.map((item, index) => (
              <Fragment key={index}>
                <GridContainerCards item xs={6}>
                  <Card
                    style={{
                      minHeight: '180px',
                      margin:
                        index === Object.keys(newsList).length
                          ? '0'
                          : '0 0 20px 0',
                    }}
                    onClick={item.handleClick}
                  >
                    <CardContent>
                      <BoxImage>
                        <MicIcon />
                      </BoxImage>
                      <BoxText>
                        <BoxCardIconInfo>
                          <CardIconInfo>
                            <TodayIcon />
                            <p>{item.date}</p>
                          </CardIconInfo>
                          <CardIconInfo>
                            {mathIconCategory[item.category] || (
                              <CategoryIcon />
                            )}
                            <p>{item.category}</p>
                          </CardIconInfo>
                        </BoxCardIconInfo>
                        <Text>{item.title}</Text>
                      </BoxText>
                      <BoxChevronCard>
                        <ChevronRightIcon />
                      </BoxChevronCard>
                    </CardContent>
                  </Card>
                </GridContainerCards>
              </Fragment>
            ))}
          </>
        )}
      </Grid>

      <Pagination count={totalPages} onChange={handleChange} page={page} />
    </Box>
  );
};
