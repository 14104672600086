import { FC } from 'react';
import { Clickable } from '../../../../../apis/news';
import { AllContent } from './all-content';

export interface NewsItem {
  id: string;
  title: string;
  category: string;
  date: string;
  slug: string;
}

export interface NewsMediaItem extends NewsItem, Clickable {
  imageAlt: string;
  imageVideo: string;
}

export const AllContentContainer: FC = () => {
  return <AllContent />;
};
