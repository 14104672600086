import { FC } from 'react';
import { PageBanner } from '../../components/page-banner/page-banner';
import { GovNewsContainer } from '../home/gov-news/gov-news.container';
import { Wave } from '../../components/wave/wave';
import {
  Mic,
  MicNone,
  Newspaper,
  NewspaperOutlined,
  Photo,
  PhotoOutlined,
  Videocam,
  VideocamOutlined,
} from '@mui/icons-material';
import { MaterialContentContainer } from '../home/gov-news/tab-news-content/material-content/material-content.container';
import { VideoContentContainer } from '../home/gov-news/tab-news-content/video-content/video-content.container';
import { PodcastContentContainer } from '../home/gov-news/tab-news-content/podcast-content/podcast-content.container';
import { GalleryContentContainer } from '../home/gov-news/tab-news-content/gallery-content/gallery-content.container';

export const News: FC = () => (
  <>
    <PageBanner
      bannerTitle="Notícias"
      bannerImage={`${process.env.PUBLIC_URL}/images/banner/banner_news.png`}
    />
    <GovNewsContainer
      titleSection={{
        title: 'Fique por dentro',
        subtitle:
          'Acompanhe aqui os últimos acontecimentos no Estado de Alagoas',
      }}
      hasSeeAll={false}
      hasFilter={true}
      tabs={[
        {
          title: 'Matérias',
          icon: <NewspaperOutlined />,
          iconSelected: <Newspaper />,
          content: <MaterialContentContainer limit={14} />,
        },
        {
          title: 'Vídeos',
          icon: <VideocamOutlined />,
          iconSelected: <Videocam />,
          content: <VideoContentContainer limit={16} />,
        },
        {
          title: 'Podcasts',
          icon: <MicNone />,
          iconSelected: <Mic />,
          content: <PodcastContentContainer limit={16} />,
        },
        {
          title: 'Galeria',
          icon: <PhotoOutlined />,
          iconSelected: <Photo />,
          content: <GalleryContentContainer limit={10} />,
        },
      ]}
    />
    <Wave
      waveColor="var(--xvia-default-background-color)"
      backgroundColor="var(--xvia-color-darkness-blue)"
    />
  </>
);
