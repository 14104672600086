import axios from 'axios';
import Agent from 'agentkeepalive';

const apiUrl = process.env.BACKEND_ENDPOINT;

export interface ICategory {
  id: string;
  slug: string;
  name: string;
  description: string;
  hidden: boolean;
  icon: string;
  orderIndex: number;
  __categories__?: string[];
  __has_categories__?: boolean;
}

const keepAliveAgent = new Agent({
  maxSockets: 100,
  maxFreeSockets: 10,
  timeout: 60000, // active socket keepalive for 60 seconds
  freeSocketTimeout: 30000, // free socket keepalive for 30 seconds
});

const axiosInstance = axios.create({ httpAgent: keepAliveAgent });

export async function getCategorys(): Promise<ICategory[]> {
  const response = await axiosInstance.get(`${apiUrl}/v1/category`);

  return response.data;
}

export const getNewsCategories = async (): Promise<ICategory[]> => [
  {
    id: '1',
    slug: 'abastecimento-de-agua',
    name: 'Abastecimento de Água',
    description: '',
    hidden: false,
    icon: '',
    orderIndex: 1,
  },
  {
    id: '2',
    slug: 'acervo-ailton-cruz',
    name: 'Acervo Ailton Cruz',
    description: '',
    hidden: false,
    icon: '',
    orderIndex: 2,
  },
  {
    id: '3',
    slug: 'acervo-jose-ronaldo',
    name: 'Acervo José Ronaldo',
    description: '',
    hidden: false,
    icon: '',
    orderIndex: 3,
  },
  {
    id: '4',
    slug: 'acervos-especiais',
    name: 'Acervos Especiais',
    description: '',
    hidden: false,
    icon: '',
    orderIndex: 4,
  },
  {
    id: '5',
    slug: 'agricultura',
    name: 'Agricultura',
    description: '',
    hidden: false,
    icon: '',
    orderIndex: 5,
  },
  {
    id: '6',
    slug: 'alagoas-contra-fake',
    name: 'Alagoas Contra Fake',
    description: '',
    hidden: false,
    icon: '',
    orderIndex: 6,
  },
  {
    id: '7',
    slug: 'alagoas-sem-fome',
    name: 'Alagoas Sem Fome',
    description: '',
    hidden: false,
    icon: '',
    orderIndex: 7,
  },
  {
    id: '8',
    slug: 'assistencia-social',
    name: 'Assistência Social',
    description: '',
    hidden: false,
    icon: '',
    orderIndex: 8,
  },
  {
    id: '9',
    slug: 'capacitacao',
    name: 'Capacitação',
    description: '',
    hidden: false,
    icon: '',
    orderIndex: 9,
  },
  {
    id: '10',
    slug: 'cidadania',
    name: 'Cidadania',
    description: '',
    hidden: false,
    icon: '',
    orderIndex: 10,
  },
  {
    id: '11',
    slug: 'comunicacao-concursos',
    name: 'Comunicação Concursos',
    description: '',
    hidden: false,
    icon: '',
    orderIndex: 11,
  },
  {
    id: '12',
    slug: 'cotidiano',
    name: 'Cotidiano',
    description: '',
    hidden: false,
    icon: '',
    orderIndex: 12,
  },
  {
    id: '13',
    slug: 'covid-19',
    name: 'Covid-19',
    description: '',
    hidden: false,
    icon: '',
    orderIndex: 13,
  },
  {
    id: '14',
    slug: 'criancas-e-adolescentes',
    name: 'Crianças e Adolescentes',
    description: '',
    hidden: false,
    icon: '',
    orderIndex: 14,
  },
  {
    id: '15',
    slug: 'cultura',
    name: 'Cultura',
    description: '',
    hidden: false,
    icon: '',
    orderIndex: 15,
  },
  {
    id: '16',
    slug: 'defesa-civil',
    name: 'Defesa Civil',
    description: '',
    hidden: false,
    icon: '',
    orderIndex: 16,
  },
  {
    id: '17',
    slug: 'desenvolvimento-economico',
    name: 'Desenvolvimento Econômico',
    description: '',
    hidden: false,
    icon: '',
    orderIndex: 17,
  },
  {
    id: '18',
    slug: 'desenvolvimento-humano',
    name: 'Desenvolvimento Humano',
    description: '',
    hidden: false,
    icon: '',
    orderIndex: 18,
  },
  {
    id: '19',
    slug: 'desenvolvimento-social',
    name: 'Desenvolvimento Social',
    description: '',
    hidden: false,
    icon: '',
    orderIndex: 19,
  },
  {
    id: '20',
    slug: 'direitos-humanos',
    name: 'Direitos Humanos',
    description: '',
    hidden: false,
    icon: '',
    orderIndex: 20,
  },
  {
    id: '21',
    slug: 'editais-e-vagas',
    name: 'Editais e Vagas',
    description: '',
    hidden: false,
    icon: '',
    orderIndex: 21,
  },
  {
    id: '22',
    slug: 'educacao',
    name: 'Educação',
    description: '',
    hidden: false,
    icon: '',
    orderIndex: 22,
  },
  {
    id: '23',
    slug: 'emprego',
    name: 'Emprego',
    description: '',
    hidden: false,
    icon: '',
    orderIndex: 23,
  },
  {
    id: '24',
    slug: 'especial',
    name: 'Especial',
    description: '',
    hidden: false,
    icon: '',
    orderIndex: 24,
  },
  {
    id: '25',
    slug: 'esporte',
    name: 'Esporte',
    description: '',
    hidden: false,
    icon: '',
    orderIndex: 25,
  },
  {
    id: '26',
    slug: 'fiscalizacao',
    name: 'Fiscalização',
    description: '',
    hidden: false,
    icon: '',
    orderIndex: 26,
  },
  {
    id: '27',
    slug: 'gestao-e-financas',
    name: 'Gestão e Finanças',
    description: '',
    hidden: false,
    icon: '',
    orderIndex: 27,
  },
  {
    id: '28',
    slug: 'governo-de-alagoas',
    name: 'Governo de Alagoas',
    description: '',
    hidden: false,
    icon: '',
    orderIndex: 28,
  },
  {
    id: '29',
    slug: 'governo-trabalhando',
    name: 'Governo Trabalhando',
    description: '',
    hidden: false,
    icon: '',
    orderIndex: 29,
  },
  {
    id: '30',
    slug: 'homenagens',
    name: 'Homenagens',
    description: '',
    hidden: false,
    icon: '',
    orderIndex: 30,
  },
  {
    id: '31',
    slug: 'inclusao-social',
    name: 'Inclusão Social',
    description: '',
    hidden: false,
    icon: '',
    orderIndex: 31,
  },
  {
    id: '32',
    slug: 'infraestrutura',
    name: 'Infraestrutura',
    description: '',
    hidden: false,
    icon: '',
    orderIndex: 32,
  },
  {
    id: '33',
    slug: 'institucional',
    name: 'Institucional',
    description: '',
    hidden: false,
    icon: '',
    orderIndex: 33,
  },
  {
    id: '34',
    slug: 'justica-e-controle-interno',
    name: 'Justiça e Controle Interno',
    description: '',
    hidden: false,
    icon: '',
    orderIndex: 34,
  },
  {
    id: '35',
    slug: 'meio-ambiente',
    name: 'Meio Ambiente',
    description: '',
    hidden: false,
    icon: '',
    orderIndex: 35,
  },
  {
    id: '36',
    slug: 'mulher',
    name: 'Mulher',
    description: '',
    hidden: false,
    icon: '',
    orderIndex: 36,
  },
  {
    id: '37',
    slug: 'nota-fiscal-cidada',
    name: 'Nota Fiscal Cidadã',
    description: '',
    hidden: false,
    icon: '',
    orderIndex: 37,
  },
  {
    id: '38',
    slug: 'orgaos-colegiados',
    name: 'Órgãos Colegiados',
    description: '',
    hidden: false,
    icon: '',
    orderIndex: 38,
  },
  {
    id: '39',
    slug: 'personalidades',
    name: 'Personalidades',
    description: '',
    hidden: false,
    icon: '',
    orderIndex: 39,
  },
  {
    id: '40',
    slug: 'planejamento-e-gestao',
    name: 'Planejamento e Gestão',
    description: '',
    hidden: false,
    icon: '',
    orderIndex: 40,
  },
  {
    id: '41',
    slug: 'primeira-infancia',
    name: 'Primeira Infância',
    description: '',
    hidden: false,
    icon: '',
    orderIndex: 41,
  },
  {
    id: '42',
    slug: 'recursos-humanos',
    name: 'Recursos Humanos',
    description: '',
    hidden: false,
    icon: '',
    orderIndex: 42,
  },
  {
    id: '43',
    slug: 'relacoes-internacionais',
    name: 'Relações Internacionais',
    description: '',
    hidden: false,
    icon: '',
    orderIndex: 43,
  },
  {
    id: '44',
    slug: 'saude',
    name: 'Saúde',
    description: '',
    hidden: false,
    icon: '',
    orderIndex: 44,
  },
  {
    id: '45',
    slug: 'secretarios',
    name: 'Secretários',
    description: '',
    hidden: false,
    icon: '',
    orderIndex: 45,
  },
  {
    id: '46',
    slug: 'seguranca',
    name: 'Segurança',
    description: '',
    hidden: false,
    icon: '',
    orderIndex: 46,
  },
  {
    id: '47',
    slug: 'servicos',
    name: 'Serviços',
    description: '',
    hidden: false,
    icon: '',
    orderIndex: 47,
  },
  {
    id: '48',
    slug: 'servidores',
    name: 'Servidores',
    description: '',
    hidden: false,
    icon: '',
    orderIndex: 48,
  },
  {
    id: '49',
    slug: 'tecnologia-e-inovacao',
    name: 'Tecnologia e Inovação',
    description: '',
    hidden: false,
    icon: '',
    orderIndex: 49,
  },
  {
    id: '50',
    slug: 'trabalho',
    name: 'Trabalho',
    description: '',
    hidden: false,
    icon: '',
    orderIndex: 50,
  },
  {
    id: '51',
    slug: 'transito',
    name: 'Trânsito',
    description: '',
    hidden: false,
    icon: '',
    orderIndex: 51,
  },
  {
    id: '52',
    slug: 'transporte',
    name: 'Transporte',
    description: '',
    hidden: false,
    icon: '',
    orderIndex: 52,
  },
  {
    id: '53',
    slug: 'turismo',
    name: 'Turismo',
    description: '',
    hidden: false,
    icon: '',
    orderIndex: 53,
  },
  {
    id: '54',
    slug: 'utilidade-publica',
    name: 'Utilidade Pública',
    description: '',
    hidden: false,
    icon: '',
    orderIndex: 54,
  },
  {
    id: '55',
    slug: 'vice-governadoria',
    name: 'Vice Governadoria',
    description: '',
    hidden: false,
    icon: '',
    orderIndex: 55,
  },
];
