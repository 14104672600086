import React, { FC } from 'react';

import { Pagination } from '../../../../../components';

import { MaterialItem } from '../material-content/material-content';
import { BiggerCard, ContainerCards } from '../material-content/style';
import { NewsCard } from '../../../../../components/cards/news-card';
import { HighlightSpan } from './style';

export type GalleryNewsItem = Omit<MaterialItem, 'handleClick'>;

interface GalleryNewsContentProps {
  newsList: MaterialItem[];
  page: number;
  totalPages: number;
  handleChange: (event: React.ChangeEvent<unknown>, value: number) => void;
}

export const GalleryContent: FC<GalleryNewsContentProps> = ({
  newsList,
  page,
  totalPages,
  handleChange,
}) => (
  <>
    {newsList?.length > 2 && <HighlightSpan>Em destaque</HighlightSpan>}
    <ContainerCards
      size={newsList?.length ? 2 : 0}
      sx={(theme) => ({ marginTop: theme.spacing(3) })}
    >
      {newsList?.slice(0, 2)?.map((item, index) => (
        <BiggerCard item={item} key={index} />
      ))}
    </ContainerCards>
    {newsList?.length > 2 && (
      <>
        <HighlightSpan sx={(theme) => ({ marginTop: theme.spacing(6) })}>
          Mais recentes
        </HighlightSpan>
        <ContainerCards
          size={newsList?.length - 2}
          sx={(theme) => ({ marginTop: theme.spacing(3) })}
        >
          {newsList?.slice(2)?.map((item, index) => (
            <NewsCard item={item} key={index} />
          ))}
        </ContainerCards>
      </>
    )}

    <Pagination count={totalPages} onChange={handleChange} page={page} />
  </>
);
