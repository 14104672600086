import { FunctionComponent } from 'react';
import { Home } from './routes/Home';
import { DynamicNews } from './routes/DynamicNews';
import { Secretary } from './routes/Secretary';
import { ServiceList } from './routes/ServiceList';
import { Catalog } from './routes/Catalog';
import { AboutUs } from './routes/AboutUs';
import { News } from './routes/News';

interface IRoutes {
  path: string;
  component: FunctionComponent<any>;
  exact?: boolean;
}

export const ROUTES = {
  HOME_PAGE: '/',
  DEPARTMENT_LIST_PAGE: '/secretarias-e-orgaos',
  ABOUT_US_PAGE: '/nosso-governo',
  DEPARTMENT_PAGE: '/secretarias-e-orgaos/:department',
  SERVICE_LIST_PAGE: '/carta-de-servicos',
  SEARCH_SERVICE_PAGE: '/search/:slug',
  SERVICE_PAGE: '/carta-de-servicos/:service',
  PUBLIC_INFO_PAGE: '/informacoes-publicas',
  NEWS_PAGE: '/noticias',
  DYNAMIC_NEWS_PAGE: '/noticias/:newsType',
  DEFAULT_PAGE: '*',
};

export const routes: IRoutes[] = [
  { path: ROUTES.HOME_PAGE, component: Home, exact: true },
  { path: ROUTES.NEWS_PAGE, component: News, exact: true },
  { path: ROUTES.DYNAMIC_NEWS_PAGE, component: DynamicNews, exact: true },
  { path: ROUTES.DEPARTMENT_LIST_PAGE, component: Secretary },
  { path: ROUTES.ABOUT_US_PAGE, component: AboutUs },
  { path: ROUTES.SERVICE_LIST_PAGE, component: ServiceList, exact: true },
  { path: ROUTES.SEARCH_SERVICE_PAGE, component: ServiceList },
  { path: ROUTES.SERVICE_PAGE, component: Catalog },
  { path: ROUTES.DEFAULT_PAGE, component: Home },
];
