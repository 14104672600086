import {
  faEnvelopeOpenText,
  faNewspaper,
  faPhone,
  faSitemap,
} from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { LinkBarProps } from '../../components/link-bar/link-bar';
import { Welcome } from './welcome';
import { ROUTES } from '../../routes';

export const WelcomeContainer = () => {
  const history = useHistory();

  const props: LinkBarProps = {
    color: '#FF891B',
    linkList: [
      {
        text: 'Secretarias e Órgãos',
        link: ROUTES.DEPARTMENT_LIST_PAGE,
        icon: faSitemap,
      },
      {
        text: 'Carta de Serviços',
        link: ROUTES.SERVICE_LIST_PAGE,
        icon: faEnvelopeOpenText,
      },
      {
        text: 'Notícias',
        link: ROUTES.NEWS_PAGE,
        icon: faNewspaper,
      },
      {
        text: 'Informações Públicas',
        link: ROUTES.PUBLIC_INFO_PAGE,
        icon: faPhone,
      },
    ].map(({ link, ...args }) => ({
      ...args,
      onClick: () => history.push(link),
    })),
  };
  return <Welcome {...props} />;
};
