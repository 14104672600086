import { styled } from '@mui/material';
import MuiBox from '@mui/material/Box';

export const BackgroundContainer = styled(MuiBox)({
  backgroundColor: 'var(--xvia-default-background-color)',
  overflow: 'hidden',
});

export const CenterContainer = styled(MuiBox)(({ theme }) => ({
  padding: '0 15px',
  margin: `0 auto ${theme.spacing(4)}`,

  '@media (min-width: 768px)': {
    width: '750px',
  },

  '@media (min-width: 992px)': {
    width: '970px',
  },

  '@media (min-width: 1200px)': {
    width: '1170px',
  },
}));
