import {
  ChangeEvent,
  useCallback,
  useEffect,
  useState,
  Fragment,
  FC,
} from 'react';
import { Fetch, Clickable } from '../../../../../apis/news';
import { useHistory } from 'react-router-dom';
import { NewsItem } from '../all-content/all-content.container';
import { ROUTES } from '../../../../../routes';
import { reorganizeNews } from '../../../../../util/news';

export type GenericNewsComponent<T extends NewsItem & Clickable> = FC<{
  directionCardColumns: 'row' | 'column';
  newsList: T[];
  page: number;
  totalPages: number;
  handleChange: (event: React.ChangeEvent<unknown>, value: number) => void;
}>;

export type ContentContainerProps<T extends NewsItem & Clickable> = {
  fetch: Fetch<T>;
  NewsComponent: GenericNewsComponent<T>;
  reorganizeItems?: boolean;
  route: 'materia' | 'video' | 'podcast';
  directionCardColumns?: 'row' | 'column';
  limit?: number;
};

export const GenericContentContainer = <T extends NewsItem & Clickable>({
  fetch,
  route,
  NewsComponent,
  directionCardColumns = 'row',
  limit = 3,
  reorganizeItems = false,
}: ContentContainerProps<T>) => {
  const [newsList, setNewsList] = useState<T[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(0);
  const history = useHistory();

  const handleChange = (_: ChangeEvent<unknown>, value: number) => {
    setPage(value > 0 ? value - 1 : 0);
  };

  const getNewsServer = useCallback(
    async (aPage: number, aLimit) => {
      try {
        const { items, total } = await fetch(aPage, aLimit);
        setTotalPages(Math.ceil(total / aLimit));
        const clickableItems = items.map(
          (item) =>
            ({
              ...item,
              handleClick: () => {
                history.push(
                  ROUTES.DYNAMIC_NEWS_PAGE.replace(':newsType', route)
                );
                window.scrollTo({ top: 0, behavior: 'auto' });
              },
            } as T)
        );
        setNewsList(
          reorganizeItems ? reorganizeNews([clickableItems])[0] : clickableItems
        );
      } catch (e) {
        console.log(e);
      }
    },
    [history, fetch, route, reorganizeItems]
  );

  useEffect(() => {
    getNewsServer(page, limit);
  }, [limit, page, getNewsServer]);

  return newsList.length ? (
    <NewsComponent
      directionCardColumns={directionCardColumns}
      newsList={newsList}
      page={page}
      totalPages={totalPages}
      handleChange={handleChange}
    />
  ) : (
    <Fragment />
  );
};
