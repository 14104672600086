import MuiCard from '@mui/material/Card';
import MuiCardContent from '@mui/material/CardContent';
import MuiBox from '@mui/material/Box';
import MuiGrid from '@mui/material/Grid';
import { Typography, styled } from '@mui/material';

export const Card = styled(MuiCard)({
  boxShadow:
    '0px 4px 8px rgba(0, 0, 0, 0.04), 0px -6px 11px rgba(0, 0, 0, 0.02)',
  display: 'flex',
  '@media screen and (max-width: 700px)': {
    margin: '20px 0 !important',
  },
});

export const CardContent = styled(MuiCardContent)({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  padding: '0 !important',
  '@media screen and (max-width: 700px)': {
    display: 'block',
  },
});

export const Box = styled(MuiBox)(({ theme }) => ({
  marginTop: theme.spacing(3),
  width: '100%',
}));

export const BoxCardIconInfo = styled(MuiBox)({
  display: 'flex',
  '@media screen and (max-width: 1200px)': {
    flexDirection: 'column',
    margin: '10px 0 0 0',
  },
  '@media screen and (max-width: 700px)': {
    flexDirection: 'row',
  },
  '@media screen and (max-width: 400px)': {
    flexDirection: 'column',
  },
});

export const BoxImage = styled(MuiBox)({
  display: 'block',
  margin: '0 15px 0 0',
  '> img': {
    display: 'flex',
    objectFit: 'cover',
  },
  '@media screen and (max-width: 1200px)': {
    margin: '0',
  },
  '@media screen and (max-width: 700px)': {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
});

export const BoxText = styled(MuiBox)({
  '@media screen and (max-width: 700px)': {
    margin: '10px',
  },
});

export const BoxChevronCard = styled(MuiBox)({
  display: 'flex',
  margin: '15px',
  '@media screen and (max-width: 1200px)': {
    margin: '0',
  },
  '@media screen and (max-width: 700px)': {
    display: 'none',
  },
});

export const GridContainerCards = styled(MuiGrid)({
  cursor: 'pointer',
  '@media screen and (max-width: 990px)': {
    display: 'contents',
  },

  '&:hover': {
    transition: '0.5s ease-out',

    '> div': {
      transition: '0.5s ease-out',
      color: 'white',
      backgroundColor: 'var(--xvia-color-darken-blue)',
    },

    span: {
      color: 'var(--xvia-color-grey-100)',
      fontWeight: 800,
    },

    'span > svg': {
      color: 'var(--xvia-color-secondary)',
    },
  },
});

export const CardIconInfo = styled(MuiBox)({
  display: 'flex',
  alignItems: 'center',
  boxShadow: 'none',
  padding: '0',
  '> svg': {
    color: '#ff891b',
  },
  '> p': {
    textAlign: 'left',
    fontSize: '14px',
    fontFamily: 'Roboto',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    margin: '0 10px',
    width: '110px',
    '@media screen and (max-width: 1200px)': {
      width: '100%',
    },
  },
});

export const Text = styled(Typography)({
  fontWeight: 500,
  fontSize: '18px',
  fontFamily: 'Roboto',
  color: '#202020',
  margin: '10px 0',
  '@media screen and (max-width: 1200px)': {
    fontSize: '16px',
  },
});
