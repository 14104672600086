import { FC } from 'react';
import { getGalleryNews } from '../../../../../apis/news';
import { GenericContentContainer } from '../generic-content/container';
import { GalleryContent } from './gallery-content';

export const GalleryContentContainer: FC<{ limit?: number; slugs?: string[] }> =
  ({ limit = 2, slugs }) => (
    <GenericContentContainer
      fetch={getGalleryNews(slugs)}
      limit={limit}
      route="materia"
      NewsComponent={GalleryContent}
    />
  );
