import { Stack, styled } from '@mui/material';
import MuiCardActions from '@mui/material/CardActions';
import MuiBox from '@mui/material/Box';

export const Button = styled(MuiBox)(({ theme }) => ({
  display: 'inline-flex',
  color: 'white',
  backgroundColor: 'var(--xvia-color-primary)',
  borderRadius: 5,
  padding: `${theme.spacing(1)} ${theme.spacing(2.3125)}`,
  fontSize: 14,
  lineHeight: '15px',
  fontWeight: 500,
  letterSpacing: '0.46px',
  fontFamily: 'Roboto',
}));

export const Card = styled(MuiBox)(({ theme }) => ({
  minWidth: 275,
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  borderRadius: 5,
  boxShadow:
    '0px 4px 8px rgba(0, 0, 0, 0.04), 0px -6px 11px rgba(0, 0, 0, 0.02)',
  overflow: 'hidden',
  color: 'var(--xvia-color-grey-90)',
  backgroundColor: 'white',

  '&:last-child': {
    padding: 0,
  },

  '&:hover': {
    transition: '0.5s ease-out',
    backgroundColor: 'var(--xvia-color-darken-blue)',

    '& *': {
      transition: '0.5s ease-out',
    },

    '& img': {
      filter: 'brightness(0.7)',
    },

    '& p': {
      color: 'white',
      fontWeight: 500,
    },

    '& .CardTitle': {
      fontWeight: 700,
    },

    '& .box': {
      backgroundColor: 'white',
      color: 'var(--xvia-color-darken-blue)',
    },
  },

  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
  },

  [theme.breakpoints.up('lg')]: {
    maxWidth: 558,
  },

  [theme.breakpoints.between('md', 'lg')]: {
    width: `calc(50% - ${theme.spacing(1.5)})`,
  },
}));

export const CardActions = styled(MuiCardActions)({
  justifyContent: 'flex-end',
  padding: 0,
  marginTop: 'auto',

  svg: {
    marginLeft: 6,
    fontSize: 15,

    path: {
      stroke: 'currentColor',
      strokeWidth: 1,
    },
  },
});

export const CardImg = styled('img')(({ theme }) => ({
  display: 'flex',
  width: 216,
  objectFit: 'cover',
  height: 228,

  [theme.breakpoints.down('lg')]: {
    width: '100%',
  },
}));

export const CardContainerInfo = styled(MuiBox)(({ theme }) => ({
  padding: theme.spacing(3),
  alignSelf: 'stretch',
  display: 'flex',
  flexGrow: 1,
  flexDirection: 'column',

  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2),
  },
}));

export const CardIconsInfo = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  boxShadow: 'none',
  padding: 0,

  '> svg': {
    color: 'var(--xvia-color-secondary)',
    maxHeight: 20,
    marginRight: theme.spacing(0.75),
  },

  '> p': {
    textAlign: 'left',
    fontSize: 14,
    lineHeight: '20px',
    fontFamily: 'Roboto',
    margin: 0,
  },
}));

export const CardNotice = styled(MuiBox)(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    height: 110,

    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 3, // Number of lines to show
    overflow: 'hidden',
    maxWidth: '100%',
  },
}));

export const CardText = styled('p')(({ theme }) => ({
  fontWeight: 400,
  fontSize: 14,
  lineHeight: '18px',
  fontFamily: 'Roboto',
  letterSpacing: '0.46px',
  color: 'var(--xvia-color-grey-90)',
  marginBottom: theme.spacing(1),
  marginRight: 10,
}));

export const CardTitle = styled('p')({
  fontWeight: 500,
  fontSize: 18,
  lineHeight: '24px',
  fontFamily: 'Roboto Serif',
  color: 'var(--xvia-color-grey-100)',
  margin: 0,
  overflow: 'hidden',
});

export const MetadataDetailsContainer = styled(Stack)(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));
