import { styled, Tabs, Tab, Box } from '@mui/material';

type ContainerProps = {
  hasFilter?: boolean;
};

export const StyledTabs = styled(Tabs)(({ theme, centered }) => ({
  minHeight: 33,
  '& .MuiTabs-flexContainer': {
    gap: centered ? 35 : theme.spacing(6),
  },
  '& .MuiTabs-indicator': {
    display: 'flex',
    top: 30,
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    width: 20,
    height: 3,
    marginLeft: 33,
    backgroundColor: '#FF891B',
  },
}));

export const StyledTab = styled(Tab)({
  fontSize: 20,
  lineHeight: '26px',
  letterSpacing: 1,
  textTransform: 'none',
  color: '#9E9E9E',
  padding: 0,
  minHeight: 26,
  minWidth: 80,

  '&.Mui-selected, &:hover': {
    color: 'var(--xvia-color-grey-100)',
    fontWeight: 700,
  },
});

export const ContentContainer = styled(Box)({
  overflow: 'hidden',
});

export const Container = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'hasFilter',
})<ContainerProps>(({ hasFilter }) => ({
  marginTop: 50,
  ...(hasFilter && {
    display: 'flex',
    justifyContent: 'space-between',
  }),
}));
