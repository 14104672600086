import { FC } from 'react';
import { Box } from '@mui/material';
import VideocamIcon from '@mui/icons-material/Videocam';
import MicIcon from '@mui/icons-material/Mic';
import {
  ContainerPodcast,
  ContainerVideo,
  ContainerVideoPodcast,
  Title,
} from './style';
import { MaterialContentContainer } from '../material-content/material-content.container';
import { VideoContentContainer } from '../video-content/video-content.container';
import { PodcastContentContainer } from '../podcast-content/podcast-content.container';

export const AllContent: FC = () => {
  return (
    <div>
      <div>
        <MaterialContentContainer />
      </div>

      <Box sx={{ flexGrow: 1 }}>
        <ContainerVideoPodcast>
          <ContainerVideo>
            <Title>
              <VideocamIcon />
              <h3>Video</h3>
            </Title>
            <VideoContentContainer
              reorganizeVideoNews
              directionCardColumns={'column'}
            />
          </ContainerVideo>
          <ContainerPodcast>
            <Title>
              <MicIcon />
              <h3>Podcasts</h3>
            </Title>
            <PodcastContentContainer
              directionCardColumns={'column'}
              reorganizeVideoNews
            />
          </ContainerPodcast>
        </ContainerVideoPodcast>
      </Box>
    </div>
  );
};
