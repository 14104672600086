import { FC } from 'react';
import { News } from './news';

export interface NewsCard {
  name: string;
  image: string;
  location?: string;
  date?: string;
  phone1: string;
  phone2: string;
  link: string;
}

export interface Category {
  description: string;
  hidden: boolean;
  icon: string;
  id: string;
  name: string;
  orderIndex: number;
  slug: string;
}

export type CategoryFilter = Pick<Category, 'id' | 'name'>;

export const NewsContainer: FC = () => <News />;
