import { FC, useCallback, useState } from 'react';
import {
  Drawer,
  Box,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { Close, FilterAlt } from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { Button } from './style';
import { TitleSection } from '../title-sections/title-sections';
import { Category, CategoryFilter } from '../../modules/list/list.container';

export interface FilterDrawerProps {
  onCategorySelect: (value: CategoryFilter) => void;
  onClickSearch: (toggleDrawer: () => void) => () => void;
  selectedCategories?: CategoryFilter[];
  categoryList?: Category[];
}

export const FilterDrawer: FC<FilterDrawerProps> = ({
  onCategorySelect,
  onClickSearch,
  selectedCategories,
  categoryList,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleDrawer = useCallback(() => setIsOpen(!isOpen), [isOpen]);

  return (
    <>
      <Button
        variant="text"
        endIcon={
          <FontAwesomeIcon
            icon={faFilter}
            color="#005EA9"
            style={{ fontSize: '30px' }}
          />
        }
        onClick={toggleDrawer}
      >
        Filtrar
      </Button>

      <Drawer anchor="right" open={isOpen} onClose={toggleDrawer}>
        <Box sx={{ p: 2 }} role="presentation">
          <Box sx={{ textAlign: 'end' }} role="presentation">
            <Button
              onClick={toggleDrawer}
              style={{
                background: 'transparent',
                color: '#000',
              }}
              endIcon={<Close sx={{ width: '20px' }} />}
            ></Button>
          </Box>
          <TitleSection
            titleSettings={{
              title: 'Filtro',
              colorTitle: '#202020',
              fontSizeTitle: '16px',
              subtitle: 'Selecione os filtros que deseja ver abaixo',
              colorSubtitle: '#7B7B7B',
              fontSizeSubtitle: '14px',
            }}
            iconSettings={{
              displayIcon: true,
              sectionIcon: <FilterAlt />,
              iconColor: '#9E9E9E',
              iconSize: '2x',
            }}
          />
          {categoryList?.length && (
            <FormGroup>
              {categoryList.map(({ id, name, slug }) => (
                <FormControlLabel
                  key={id}
                  control={
                    <Checkbox
                      checked={selectedCategories?.some(
                        ({ id: aId }) => aId === id
                      )}
                      onChange={() => onCategorySelect({ id, name, slug })}
                    />
                  }
                  label={name}
                />
              ))}
            </FormGroup>
          )}
          <Box sx={{ textAlign: 'end', p: 3 }} role="presentation">
            <Button variant="contained" onClick={onClickSearch(toggleDrawer)}>
              Filtrar
            </Button>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};
