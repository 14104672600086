import { FC } from 'react';
import { MaterialContent } from './material-content';
import { getNews } from '../../../../../apis/news';
import { GenericContentContainer } from '../generic-content/container';

export type MaterialContentContainerProps = {
  limit?: number;
  slugs?: string[];
};

export const MaterialContentContainer: FC<MaterialContentContainerProps> = ({
  limit = 3,
  slugs,
}) => (
  <GenericContentContainer
    fetch={getNews(slugs)}
    route="materia"
    limit={limit}
    NewsComponent={MaterialContent}
  />
);
