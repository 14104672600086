import { FC } from 'react';
import { Element } from 'react-scroll';
import { FooterContainer } from '../modules/footer/footer.container';
import { NewsContainer } from '../modules/news/news.container';

export const News: FC = () => {
  return (
    <Element name="news">
      <NewsContainer />
      <FooterContainer />
    </Element>
  );
};
