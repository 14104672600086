import {
  NewsItem,
  NewsMediaItem,
} from '../modules/home/gov-news/tab-news-content/all-content/all-content.container';
import { MaterialItem } from '../modules/home/gov-news/tab-news-content/material-content/material-content';

const imageUrl = process.env.PUBLIC_URL;

export type Clickable = {
  handleClick: () => void;
};

export type ApiResponse<T extends NewsItem & Clickable> = {
  items: Omit<T, 'handleClick'>[];
  total: number;
};

export type Fetch<T extends NewsItem & Clickable> = (
  page: number,
  limit: number
) => Promise<ApiResponse<T>>;

export type WrapFetch<T extends NewsItem & Clickable> = (
  slugs?: string[]
) => Fetch<T>;

const paginateResponse = <T extends NewsItem & Clickable>(
  start: number,
  limit: number,
  items: Omit<T, 'handleClick'>[]
): ApiResponse<T> => ({
  items: items.slice(start, start + limit),
  total: items.length,
});

const filterIfSlug = <T extends NewsItem & Clickable>(
  paginatedResponse: ApiResponse<T>,
  slugs: string[] | undefined
) =>
  slugs?.length
    ? {
        ...paginatedResponse,
        items: paginatedResponse.items.filter(({ slug }) =>
          slugs.includes(slug)
        ),
      }
    : paginatedResponse;

export const getNews: WrapFetch<MaterialItem> =
  (slugs) =>
  async (page = 0, limit = 3) => {
    const mockApi = [
      {
        id: '1',
        title:
          'Provas da Olimpíada Brasileira de Matemática das Escolas Públicas são aplicadas nos 102 municípios',
        text: 'As provas da 19a edição da Olimpíada Brasileira de Matemática das Escolas Públicas (OBMEP) foram aplicadas nos 102 municípios alagoan...',
        category: 'Educação e Pesquisa',
        slug: 'educacao-e-pesquisa',
        date: 'há 12 minutos',
        image: `${imageUrl}/images/gov-news/news_04.png`,
        imageAlt:
          'Provas da Olimpíada Brasileira de Matemática das Escolas Públicas são aplicadas nos 102 municípios',
      },
      {
        id: '2',
        title:
          'Curso de Suporte Básico de Vida capacita mais 40 socorristas do Samu São Paulo',
        text: 'Capacitação abordou temas de urgência e emergência no atendimento pré-hospitalar',
        category: 'Saúde',
        slug: 'saude',
        date: 'há 3 horas',
        image: `${imageUrl}/images/gov-news/news_02.png`,
        imageAlt:
          'Curso de Suporte Básico de Vida capacita mais 40 socorristas do Samu São Paulo',
      },
      {
        id: '3',
        title:
          'Cobertura vacinal infantil em São Paulo registra aumento em 2024, aponta Ministério da Saúde',
        text: 'Dos 16 imunizantes avaliados pelo...',
        category: 'Saúde',
        slug: 'saude',
        date: 'há 1 semana',
        image: `${imageUrl}/images/gov-news/news_03.png`,
        imageAlt:
          'Cobertura vacinal infantil em São Paulo registra aumento em 2024, aponta Ministério da Saúde',
      },
      {
        id: '4',
        title:
          'Curso de Suporte Básico de Vida capacita mais 40 socorristas do Samu São Paulo',
        text: 'Capacitação abordou temas de urgência e emergência no atendimento pré-hospitalar',
        category: 'Saúde',
        slug: 'saude',
        date: 'há 3 horas',
        image: `${imageUrl}/images/gov-news/news_02.png`,
        imageAlt:
          'Curso de Suporte Básico de Vida capacita mais 40 socorristas do Samu São Paulo',
      },
      {
        id: '5',
        title:
          'Cobertura vacinal infantil em São Paulo registra aumento em 2024, aponta Ministério da Saúde',
        text: 'Dos 16 imunizantes avaliados pelo...',
        category: 'Saúde',
        slug: 'saude',
        date: 'há 1 semana',
        image: `${imageUrl}/images/gov-news/news_03.png`,
        imageAlt:
          'Cobertura vacinal infantil em São Paulo registra aumento em 2024, aponta Ministério da Saúde',
      },
      {
        id: '6',
        title:
          'Curso de Suporte Básico de Vida capacita mais 40 socorristas do Samu São Paulo',
        text: 'Capacitação abordou temas de urgência e emergência no atendimento pré-hospitalar',
        category: 'Saúde',
        slug: 'saude',
        date: 'há 3 horas',
        image: `${imageUrl}/images/gov-news/news_02.png`,
        imageAlt:
          'Curso de Suporte Básico de Vida capacita mais 40 socorristas do Samu São Paulo',
      },
      {
        id: '7',
        title:
          'Cobertura vacinal infantil em São Paulo registra aumento em 2024, aponta Ministério da Saúde',
        text: 'Dos 16 imunizantes avaliados pelo...',
        category: 'Saúde',
        slug: 'saude',
        date: 'há 1 semana',
        image: `${imageUrl}/images/gov-news/news_03.png`,
        imageAlt:
          'Cobertura vacinal infantil em São Paulo registra aumento em 2024, aponta Ministério da Saúde',
      },
      {
        id: '8',
        title:
          'Cobertura vacinal infantil em São Paulo registra aumento em 2024, aponta Ministério da Saúde',
        text: 'Dos 16 imunizantes avaliados pelo...',
        category: 'Saúde',
        slug: 'saude',
        date: 'há 1 semana',
        image: `${imageUrl}/images/gov-news/news_03.png`,
        imageAlt:
          'Cobertura vacinal infantil em São Paulo registra aumento em 2024, aponta Ministério da Saúde',
      },
      {
        id: '9',
        title:
          'Curso de Suporte Básico de Vida capacita mais 40 socorristas do Samu São Paulo',
        text: 'Capacitação abordou temas de urgência e emergência no atendimento pré-hospitalar',
        category: 'Saúde',
        slug: 'saude',
        date: 'há 3 horas',
        image: `${imageUrl}/images/gov-news/news_02.png`,
        imageAlt:
          'Curso de Suporte Básico de Vida capacita mais 40 socorristas do Samu São Paulo',
      },
      {
        id: '10',
        title:
          'Cobertura vacinal infantil em São Paulo registra aumento em 2024, aponta Ministério da Saúde',
        text: 'Dos 16 imunizantes avaliados pelo...',
        category: 'Saúde',
        slug: 'saude',
        date: 'há 1 semana',
        image: `${imageUrl}/images/gov-news/news_03.png`,
        imageAlt:
          'Cobertura vacinal infantil em São Paulo registra aumento em 2024, aponta Ministério da Saúde',
      },
      {
        id: '11',
        title:
          'Curso de Suporte Básico de Vida capacita mais 40 socorristas do Samu São Paulo',
        text: 'Capacitação abordou temas de urgência e emergência no atendimento pré-hospitalar',
        category: 'Saúde',
        slug: 'saude',
        date: 'há 3 horas',
        image: `${imageUrl}/images/gov-news/news_02.png`,
        imageAlt:
          'Curso de Suporte Básico de Vida capacita mais 40 socorristas do Samu São Paulo',
      },
      {
        id: '12',
        title:
          'Provas da Olimpíada Brasileira de Matemática das Escolas Públicas são aplicadas nos 102 municípios',
        text: 'As provas da 19a edição da Olimpíada Brasileira de Matemática das Escolas Públicas (OBMEP) foram aplicadas nos 102 municípios alagoan...',
        category: 'Educação e Pesquisa',
        slug: 'educacao-e-pesquisa',
        date: 'há 12 minutos',
        image: `${imageUrl}/images/gov-news/news_04.png`,
        imageAlt:
          'Provas da Olimpíada Brasileira de Matemática das Escolas Públicas são aplicadas nos 102 municípios',
      },
      {
        id: '13',
        title:
          'Cobertura vacinal infantil em São Paulo registra aumento em 2024, aponta Ministério da Saúde',
        text: 'Dos 16 imunizantes avaliados pelo...',
        category: 'Saúde',
        slug: 'saude',
        date: 'há 1 semana',
        image: `${imageUrl}/images/gov-news/news_03.png`,
        imageAlt:
          'Cobertura vacinal infantil em São Paulo registra aumento em 2024, aponta Ministério da Saúde',
      },
      {
        id: '14',
        title:
          'Curso de Suporte Básico de Vida capacita mais 40 socorristas do Samu São Paulo',
        text: 'Capacitação abordou temas de urgência e emergência no atendimento pré-hospitalar',
        category: 'Saúde',
        slug: 'saude',
        date: 'há 3 horas',
        image: `${imageUrl}/images/gov-news/news_02.png`,
        imageAlt:
          'Curso de Suporte Básico de Vida capacita mais 40 socorristas do Samu São Paulo',
      },
      {
        id: '15',
        title:
          'Cobertura vacinal infantil em São Paulo registra aumento em 2024, aponta Ministério da Saúde',
        text: 'Dos 16 imunizantes avaliados pelo...',
        category: 'Saúde',
        slug: 'saude',
        date: 'há 1 semana',
        image: `${imageUrl}/images/gov-news/news_03.png`,
        imageAlt:
          'Cobertura vacinal infantil em São Paulo registra aumento em 2024, aponta Ministério da Saúde',
      },
      {
        id: '16',
        title:
          'Curso de Suporte Básico de Vida capacita mais 40 socorristas do Samu São Paulo',
        text: 'Capacitação abordou temas de urgência e emergência no atendimento pré-hospitalar',
        category: 'Saúde',
        slug: 'saude',
        date: 'há 3 horas',
        image: `${imageUrl}/images/gov-news/news_02.png`,
        imageAlt:
          'Curso de Suporte Básico de Vida capacita mais 40 socorristas do Samu São Paulo',
      },
    ];
    return filterIfSlug(paginateResponse(page * limit, limit, mockApi), slugs);
  };

export const getVideoNews: WrapFetch<NewsMediaItem> =
  (slugs) =>
  async (page = 0, limit = 4) => {
    const mockApi = [
      {
        id: '1',
        title:
          'CRIA amplia discussão sobre a educação alimentar e nutricional, e promove seminário em São Paulo',
        category: 'Saúde',
        slug: 'saude',
        date: 'há 19 horas',
        imageVideo: `${imageUrl}/images/gov-video-news/video_01.png`,
        imageAlt: '',
      },
      {
        id: '2',
        title:
          "Secretaria de Estado do Meio Ambiente inicia a campanha 'Coleta ON, Resíduo OFF'",
        category: 'Educação e Pesquisa',
        slug: 'educacao-e-pesquisa',
        date: 'Meio Ambiente e Produção Rural',
        imageVideo: `${imageUrl}/images/gov-video-news/video_02.png`,
        imageAlt: '',
      },
      {
        id: '3',
        title:
          'CRIA amplia discussão sobre a educação alimentar e nutricional, e promove seminário em São Paulo',
        category: 'Saúde',
        slug: 'saude',
        date: 'há 5 dias',
        imageVideo: `${imageUrl}/images/gov-video-news/video_02.png`,
        imageAlt: '',
      },
      {
        id: '4',
        title:
          "Secretaria de Estado do Meio Ambiente inicia a campanha 'Coleta ON, Resíduo OFF'",
        category: 'Meio Ambiente e Produção Rural',
        slug: 'meio-ambiente-e-producao-rural',
        date: 'há 5 dias',
        imageVideo: `${imageUrl}/images/gov-video-news/video_01.png`,
        imageAlt: '',
      },
      {
        id: '5',
        title:
          'CRIA amplia discussão sobre a educação alimentar e nutricional, e promove seminário em São Paulo',
        category: 'Saúde',
        slug: 'saude',
        date: 'há 19 horas',
        imageVideo: `${imageUrl}/images/gov-video-news/video_01.png`,
        imageAlt: '',
      },
      {
        id: '6',
        title:
          "Secretaria de Estado do Meio Ambiente inicia a campanha 'Coleta ON, Resíduo OFF'",
        category: 'Educação e Pesquisa',
        slug: 'educacao-e-pesquisa',
        date: 'Meio Ambiente e Produção Rural',
        imageVideo: `${imageUrl}/images/gov-video-news/video_02.png`,
        imageAlt: '',
      },
      {
        id: '7',
        title:
          'CRIA amplia discussão sobre a educação alimentar e nutricional, e promove seminário em São Paulo',
        category: 'Saúde',
        slug: 'saude',
        date: 'há 5 dias',
        imageVideo: `${imageUrl}/images/gov-video-news/video_02.png`,
        imageAlt: '',
      },
      {
        id: '8',
        title:
          "Secretaria de Estado do Meio Ambiente inicia a campanha 'Coleta ON, Resíduo OFF'",
        category: 'Meio Ambiente e Produção Rural',
        slug: 'meio-ambiente-e-producao-rural',
        date: 'há 5 dias',
        imageVideo: `${imageUrl}/images/gov-video-news/video_01.png`,
        imageAlt: '',
      },
      {
        id: '9',
        title:
          'CRIA amplia discussão sobre a educação alimentar e nutricional, e promove seminário em São Paulo',
        category: 'Saúde',
        slug: 'saude',
        date: 'há 19 horas',
        imageVideo: `${imageUrl}/images/gov-video-news/video_01.png`,
        imageAlt: '',
      },
      {
        id: '10',
        title:
          "Secretaria de Estado do Meio Ambiente inicia a campanha 'Coleta ON, Resíduo OFF'",
        category: 'Educação e Pesquisa',
        slug: 'educacao-e-pesquisa',
        date: 'Meio Ambiente e Produção Rural',
        imageVideo: `${imageUrl}/images/gov-video-news/video_02.png`,
        imageAlt: '',
      },
      {
        id: '11',
        title:
          'CRIA amplia discussão sobre a educação alimentar e nutricional, e promove seminário em São Paulo',
        category: 'Saúde',
        slug: 'saude',
        date: 'há 5 dias',
        imageVideo: `${imageUrl}/images/gov-video-news/video_02.png`,
        imageAlt: '',
      },
      {
        id: '12',
        title:
          "Secretaria de Estado do Meio Ambiente inicia a campanha 'Coleta ON, Resíduo OFF'",
        category: 'Meio Ambiente e Produção Rural',
        slug: 'meio-ambiente-e-producao-rural',
        date: 'há 5 dias',
        imageVideo: `${imageUrl}/images/gov-video-news/video_01.png`,
        imageAlt: '',
      },
      {
        id: '13',
        title:
          'CRIA amplia discussão sobre a educação alimentar e nutricional, e promove seminário em São Paulo',
        category: 'Saúde',
        slug: 'saude',
        date: 'há 19 horas',
        imageVideo: `${imageUrl}/images/gov-video-news/video_01.png`,
        imageAlt: '',
      },
      {
        id: '14',
        title:
          "Secretaria de Estado do Meio Ambiente inicia a campanha 'Coleta ON, Resíduo OFF'",
        category: 'Educação e Pesquisa',
        slug: 'educacao-e-pesquisa',
        date: 'Meio Ambiente e Produção Rural',
        imageVideo: `${imageUrl}/images/gov-video-news/video_02.png`,
        imageAlt: '',
      },
      {
        id: '15',
        title:
          'CRIA amplia discussão sobre a educação alimentar e nutricional, e promove seminário em São Paulo',
        category: 'Saúde',
        slug: 'saude',
        date: 'há 5 dias',
        imageVideo: `${imageUrl}/images/gov-video-news/video_02.png`,
        imageAlt: '',
      },
      {
        id: '16',
        title:
          "Secretaria de Estado do Meio Ambiente inicia a campanha 'Coleta ON, Resíduo OFF'",
        category: 'Meio Ambiente e Produção Rural',
        slug: 'meio-ambiente-e-producao-rural',
        date: 'há 5 dias',
        imageVideo: `${imageUrl}/images/gov-video-news/video_01.png`,
        imageAlt: '',
      },
    ];
    return filterIfSlug(paginateResponse(page * limit, limit, mockApi), slugs);
  };

export const getPodcastNews: WrapFetch<NewsItem & Clickable> =
  (slugs) =>
  async (page = 0, limit = 4) => {
    const mockApi = [
      {
        id: '1',
        title:
          'Na Expobacia Leiteira, Paulo Dantas lança programa e assina termo de fomento ao cooperativismo alagoano',
        category: 'Saúde',
        slug: 'saude',
        date: 'há 19 horas',
      },
      {
        id: '2',
        title:
          'Seduc publica na próxima semana relação de servidores beneficiados com precatórios do Fundef',
        category: 'Educação e Pesquisa',
        slug: 'educacao-e-pesquisa',
        date: 'há 2 semanas',
      },
      {
        id: '3',
        title:
          'CRIA amplia discussão sobre a educação alimentar e nutricional, e promove seminário em São Paulo',
        category: 'Saúde',
        slug: 'saude',
        date: 'há 5 dias',
      },
      {
        id: '4',
        title:
          "Secretaria de Estado do Meio Ambiente inicia a campanha 'Coleta ON, Resíduo OFF'",
        category: 'Meio Ambiente e Produção Rural',
        slug: 'meio-ambiente-e-producao-rural',
        date: 'há 5 dias',
      },
      {
        id: '5',
        title:
          "Secretaria de Estado do Meio Ambiente inicia a campanha 'Coleta ON, Resíduo OFF'",
        category: 'Educação e Pesquisa',
        slug: 'educacao-e-pesquisa',
        date: 'Meio Ambiente e Produção Rural',
      },
      {
        id: '6',
        title:
          'CRIA amplia discussão sobre a educação alimentar e nutricional, e promove seminário em São Paulo',
        category: 'Saúde',
        slug: 'saude',
        date: 'há 19 horas',
      },
      {
        id: '7',
        title:
          "Secretaria de Estado do Meio Ambiente inicia a campanha 'Coleta ON, Resíduo OFF'",
        category: 'Meio Ambiente e Produção Rural',
        slug: 'meio-ambiente-e-producao-rural',
        date: 'há 5 dias',
      },
      {
        id: '8',
        title:
          'CRIA amplia discussão sobre a educação alimentar e nutricional, e promove seminário em São Paulo',
        category: 'Saúde',
        slug: 'saude',
        date: 'há 5 dias',
      },
      {
        id: '9',
        title:
          'Na Expobacia Leiteira, Paulo Dantas lança programa e assina termo de fomento ao cooperativismo alagoano',
        category: 'Saúde',
        slug: 'saude',
        date: 'há 19 horas',
      },
      {
        id: '10',
        title:
          'Seduc publica na próxima semana relação de servidores beneficiados com precatórios do Fundef',
        category: 'Educação e Pesquisa',
        slug: 'educacao-e-pesquisa',
        date: 'há 2 semanas',
      },
      {
        id: '11',
        title:
          'CRIA amplia discussão sobre a educação alimentar e nutricional, e promove seminário em São Paulo',
        category: 'Saúde',
        slug: 'saude',
        date: 'há 5 dias',
      },
      {
        id: '12',
        title:
          "Secretaria de Estado do Meio Ambiente inicia a campanha 'Coleta ON, Resíduo OFF'",
        category: 'Meio Ambiente e Produção Rural',
        slug: 'meio-ambiente-e-producao-rural',
        date: 'há 5 dias',
      },
      {
        id: '13',
        title:
          "Secretaria de Estado do Meio Ambiente inicia a campanha 'Coleta ON, Resíduo OFF'",
        category: 'Educação e Pesquisa',
        slug: 'educacao-e-pesquisa',
        date: 'Meio Ambiente e Produção Rural',
      },
      {
        id: '14',
        title:
          'CRIA amplia discussão sobre a educação alimentar e nutricional, e promove seminário em São Paulo',
        category: 'Saúde',
        slug: 'saude',
        date: 'há 19 horas',
      },
      {
        id: '15',
        title:
          "Secretaria de Estado do Meio Ambiente inicia a campanha 'Coleta ON, Resíduo OFF'",
        category: 'Meio Ambiente e Produção Rural',
        slug: 'meio-ambiente-e-producao-rural',
        date: 'há 5 dias',
      },
      {
        id: '16',
        title:
          'CRIA amplia discussão sobre a educação alimentar e nutricional, e promove seminário em São Paulo',
        category: 'Saúde',
        slug: 'saude',
        date: 'há 5 dias',
      },
    ];
    return filterIfSlug(paginateResponse(page * limit, limit, mockApi), slugs);
  };

export const getGalleryNews: WrapFetch<MaterialItem> =
  (slugs) =>
  async (page = 0, limit = 2) => {
    const mockApi = [
      {
        id: '1',
        title:
          'Provas da Olimpíada Brasileira de Matemática das Escolas Públicas são aplicadas nos 102 municípios',
        text: 'As provas da 19a edição da Olimpíada Brasileira de Matemática das Escolas Públicas (OBMEP) foram aplicadas nos 102 municípios alagoan...',
        category: 'Educação e Pesquisa',
        slug: 'educacao-e-pesquisa',
        date: 'há 12 minutos',
        image: `${imageUrl}/images/gov-news/news_04.png`,
        imageAlt:
          'Provas da Olimpíada Brasileira de Matemática das Escolas Públicas são aplicadas nos 102 municípios',
      },
      {
        id: '2',
        title:
          'Curso de Suporte Básico de Vida capacita mais 40 socorristas do Samu São Paulo',
        text: 'Capacitação abordou temas de urgência e emergência no atendimento pré-hospitalar',
        category: 'Saúde',
        slug: 'saude',
        date: 'há 3 horas',
        image: `${imageUrl}/images/gov-news/news_04.png`,
        imageAlt:
          'Curso de Suporte Básico de Vida capacita mais 40 socorristas do Samu São Paulo',
      },
      {
        id: '3',
        title:
          'Curso de Suporte Básico de Vida capacita mais 40 socorristas do Samu São Paulo',
        text: 'Capacitação abordou temas de urgência e emergência no atendimento pré-hospitalar',
        category: 'Saúde',
        slug: 'saude',
        date: 'há 3 horas',
        image: `${imageUrl}/images/gov-news/news_02.png`,
        imageAlt:
          'Curso de Suporte Básico de Vida capacita mais 40 socorristas do Samu São Paulo',
      },
      {
        id: '4',
        title:
          'Cobertura vacinal infantil em São Paulo registra aumento em 2024, aponta Ministério da Saúde',
        text: 'Dos 16 imunizantes avaliados pelo...',
        category: 'Saúde',
        slug: 'saude',
        date: 'há 1 semana',
        image: `${imageUrl}/images/gov-news/news_03.png`,
        imageAlt:
          'Cobertura vacinal infantil em São Paulo registra aumento em 2024, aponta Ministério da Saúde',
      },
      {
        id: '5',
        title:
          'Curso de Suporte Básico de Vida capacita mais 40 socorristas do Samu São Paulo',
        text: 'Capacitação abordou temas de urgência e emergência no atendimento pré-hospitalar',
        category: 'Saúde',
        slug: 'saude',
        date: 'há 3 horas',
        image: `${imageUrl}/images/gov-news/news_02.png`,
        imageAlt:
          'Curso de Suporte Básico de Vida capacita mais 40 socorristas do Samu São Paulo',
      },
      {
        id: '6',
        title:
          'Cobertura vacinal infantil em São Paulo registra aumento em 2024, aponta Ministério da Saúde',
        text: 'Dos 16 imunizantes avaliados pelo...',
        category: 'Saúde',
        slug: 'saude',
        date: 'há 1 semana',
        image: `${imageUrl}/images/gov-news/news_03.png`,
        imageAlt:
          'Cobertura vacinal infantil em São Paulo registra aumento em 2024, aponta Ministério da Saúde',
      },
      {
        id: '7',
        title:
          'Cobertura vacinal infantil em São Paulo registra aumento em 2024, aponta Ministério da Saúde',
        text: 'Dos 16 imunizantes avaliados pelo...',
        category: 'Saúde',
        slug: 'saude',
        date: 'há 1 semana',
        image: `${imageUrl}/images/gov-news/news_03.png`,
        imageAlt:
          'Cobertura vacinal infantil em São Paulo registra aumento em 2024, aponta Ministério da Saúde',
      },
      {
        id: '8',
        title:
          'Curso de Suporte Básico de Vida capacita mais 40 socorristas do Samu São Paulo',
        text: 'Capacitação abordou temas de urgência e emergência no atendimento pré-hospitalar',
        category: 'Saúde',
        slug: 'saude',
        date: 'há 3 horas',
        image: `${imageUrl}/images/gov-news/news_02.png`,
        imageAlt:
          'Curso de Suporte Básico de Vida capacita mais 40 socorristas do Samu São Paulo',
      },
      {
        id: '9',
        title:
          'Cobertura vacinal infantil em São Paulo registra aumento em 2024, aponta Ministério da Saúde',
        text: 'Dos 16 imunizantes avaliados pelo...',
        category: 'Saúde',
        slug: 'saude',
        date: 'há 1 semana',
        image: `${imageUrl}/images/gov-news/news_03.png`,
        imageAlt:
          'Cobertura vacinal infantil em São Paulo registra aumento em 2024, aponta Ministério da Saúde',
      },
      {
        id: '10',
        title:
          'Curso de Suporte Básico de Vida capacita mais 40 socorristas do Samu São Paulo',
        text: 'Capacitação abordou temas de urgência e emergência no atendimento pré-hospitalar',
        category: 'Saúde',
        slug: 'saude',
        date: 'há 3 horas',
        image: `${imageUrl}/images/gov-news/news_02.png`,
        imageAlt:
          'Curso de Suporte Básico de Vida capacita mais 40 socorristas do Samu São Paulo',
      },
    ];

    return filterIfSlug(paginateResponse(page * limit, limit, mockApi), slugs);
  };

export async function getSimilarNews(
  newsType?: string
): Promise<Omit<MaterialItem, 'handleClick'>[]> {
  return newsType === 'video'
    ? [
        {
          id: '1',
          title:
            'Curso de Suporte Básico de Vida capacita mais 40 socorristas do Samu São Paulo',
          text: 'Capacitação abordou temas de urgência e ...',
          category: 'Saúde',
          slug: 'saude',
          date: 'há 3 horas',
          image: `${imageUrl}/images/gov-news/news_02_bigger.jpeg`,
          imageAlt:
            'Curso de Suporte Básico de Vida capacita mais 40 socorristas do Samu São Paulo',
        },
        {
          id: '2',
          title:
            'Cobertura vacinal infantil em São Paulo registra aumento em 2024, aponta Ministério da Saúde',
          text: 'Dos 16 imunizantes avaliados pelo...',
          category: 'Saúde',
          slug: 'saude',
          date: 'há 1 semana',
          image: `${imageUrl}/images/gov-news/news_03_bigger.jpeg`,
          imageAlt:
            'Cobertura vacinal infantil em São Paulo registra aumento em 2024, aponta Ministério da Saúde',
        },
      ]
    : [
        {
          id: '1',
          title:
            'CRIA amplia discussão sobre a educação alimentar e nutricional, e promove seminário em São Paulo',
          text: '',
          category: 'Saúde',
          slug: 'saude',
          date: 'há 19 horas',
          image: `${imageUrl}/images/gov-video-news/video_01.png`,
          imageAlt: '',
        },
        {
          id: '2',
          title:
            "Secretaria de Estado do Meio Ambiente inicia a campanha 'Coleta ON, Resíduo OFF'",
          text: '',
          category: 'Educação e Pesquisa',
          slug: 'educacao-e-pesquisa',
          date: 'Meio Ambiente e Produção Rural',
          image: `${imageUrl}/images/gov-video-news/video_02.png`,
          imageAlt: '',
        },
      ];
}
