import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { useMemo, FC } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
export interface BreadcrumbItem {
  name: string;
  path?: string;
}
export interface BreadcrumbProps {
  className?: string;
  items: BreadcrumbItem[];
}

export const Breadcrumb: FC<BreadcrumbProps> = ({ className, items }) => {
  const breadcrumbItems = useMemo(() => {
    const extraBreadcrumbItems = items.map(({ name, path }) =>
      path ? (
        <Link key={name} to={path}>
          {name}
        </Link>
      ) : (
        <Typography fontSize="14px" key={name}>
          {name}
        </Typography>
      )
    );

    return [
      <Link key="home" to="/">
        Página Inicial
      </Link>,
      ...extraBreadcrumbItems,
    ];
  }, [items]);

  const breadcrumbsClassName = useMemo(
    () =>
      classNames({
        'xvia-breadcrumb': true,
        [className as string]: !!className,
      }),
    [className]
  );

  return (
    <Stack spacing={2}>
      <Breadcrumbs
        className={breadcrumbsClassName}
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        sx={{
          color: '#fff',
          fontSize: '14px',
          fontWeight: 400,
          '& a': {
            color: '#fff',
            textDecoration: 'none',
            '&:hover': {
              color: 'var(--xvia-bg-gray)',
            },
          },
        }}
      >
        {breadcrumbItems}
      </Breadcrumbs>
    </Stack>
  );
};
