import { NewsItem } from '../modules/home/gov-news/tab-news-content/all-content/all-content.container';

export const reorganizeNews = <T extends NewsItem>(
  newsArray: T[][],
  itemsPerGroup = 2
): T[][] => {
  const newNews: T[][] = [];
  let tempArray: T[] = [];

  for (const item of newsArray.flat()) {
    tempArray.push(item);
    if (tempArray.length === itemsPerGroup) {
      newNews.push([...tempArray]);
      tempArray = [];
    }
  }

  if (tempArray.length) {
    newNews.push([...tempArray]);
  }

  return newNews;
};
