import { FC } from 'react';
import { VideoContent } from './video-content';
import { getVideoNews } from '../../../../../apis/news';
import { GenericContentContainer } from '../generic-content/container';

interface VideoContentContainerProps {
  reorganizeVideoNews?: boolean;
  directionCardColumns?: 'row' | 'column';
  limit?: number;
  slugs?: string[];
}

export const VideoContentContainer: FC<VideoContentContainerProps> = ({
  reorganizeVideoNews,
  directionCardColumns,
  slugs,
  limit = 4,
}) => (
  <GenericContentContainer
    fetch={getVideoNews(slugs)}
    route="video"
    limit={limit}
    reorganizeItems={reorganizeVideoNews}
    directionCardColumns={directionCardColumns}
    NewsComponent={VideoContent}
  />
);
