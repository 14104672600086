import { Button as MuiButton, styled } from '@mui/material';

export const Button = styled(MuiButton)({
  color: '#005EA9',
  fontFamily: 'Roboto',
  fontSize: '18px',
  fontWeight: 500,
  lineHeight: '22px',
  letterSpacing: '0.46px',
  textAlign: 'left',
  borderRadius: 0,
  boxShadow: 'none',
  textTransform: 'none',
  padding: 0,
});
