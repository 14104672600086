import { Box as MuiBox, styled } from '@mui/material';

export const List = styled('ul')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  listStyle: 'none',
  padding: 0,
  margin: 0,
});

export const PaginationButton = styled('button')(({ theme }) => ({
  display: 'flex',
  cursor: 'pointer',
  color: '#7B7B7B',
  background: 'none',
  fontWeight: 500,
  border: 0,
  fontSize: '22px',
  alignItems: 'center',
  justifyContent: 'center',

  '& svg': {
    color: theme.palette.primary.main,
    width: '35px',
    height: '35px',
    transition: 'color 0.3s ease',
  },

  '&:disabled': {
    cursor: 'default',
    '& svg': {
      color: '#7B7B7B',
      '&:hover': {
        color: '#7B7B7B',
      },
    },
  },

  '&:hover': {
    color: theme.palette.secondary.main,
    '& svg': {
      color: theme.palette.secondary.main,
    },
  },

  '&[aria-current="true"]': {
    color: theme.palette.secondary.main,
  },
}));

export const ContainerPagination = styled(MuiBox)(({ theme }) => ({
  marginTop: theme.spacing(3),
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
}));
