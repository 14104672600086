import { FC } from 'react';
import { TitleSection } from '../../../components/title-sections/title-sections';
import Slider, { Settings } from 'react-slick';
import { TabsProps, TabsNews } from '../../../components/tabs-news/tabs-news';

import NewspaperIcon from '@mui/icons-material/Newspaper';

import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { BackgroundContainer, CenterContainer } from './style';

export interface GovNewsProps {
  sliderSettings: Settings;
  tabsProps: TabsProps;
  govPrograms: { img: string; alt: string }[];
  titleSection: { title: string; subtitle: string };
  hasSeeAll: boolean;
}

export const GovNews: FC<GovNewsProps> = ({
  sliderSettings,
  tabsProps,
  govPrograms,
  titleSection: { title, subtitle },
  hasSeeAll,
}) => (
  <BackgroundContainer>
    <CenterContainer>
      <TitleSection
        titleSettings={{
          title,
          colorTitle: '#202020',
          subtitle,
          colorSubtitle: '#7B7B7B',
        }}
        iconSettings={{
          displayIcon: true,
          sectionIcon: <NewspaperIcon />,
          iconColor: '#9E9E9E',
          iconSize: '40px',
        }}
        buttonSeeMoreSettings={
          hasSeeAll
            ? {
                buttonLabel: 'Ver todas',
                buttonIcon: <RemoveRedEyeIcon />,
              }
            : undefined
        }
      />
      <div className="xvia-gov-news__container_slider_imgs">
        <Slider {...sliderSettings}>
          {govPrograms.map(({ img, alt }, index) => (
            <div key={index} className="">
              <div className="xvia-gov-news__slider_imgs">
                <img src={img} alt={alt} />
              </div>
            </div>
          ))}
        </Slider>
      </div>
      <TabsNews {...tabsProps} />
    </CenterContainer>
  </BackgroundContainer>
);
