import { FC, useCallback, useEffect, useState } from 'react';
import { SimilarDynamicNews } from './similar-dynamic-news';
import { getSimilarNews } from '../../../apis/news';
import { MaterialItem } from '../../home/gov-news/tab-news-content/material-content/material-content';

import {
  ActionsInCard,
  BoxCardIconInfo,
  BoxChevronCard,
  BoxImage,
  BoxPodcastIcon,
  BoxText,
  Button,
  Card,
  CardContainerImg,
  CardContainerInfo,
  CardContentDynamicNews,
  CardIconInfo,
  CardIconsInfo,
  CardText,
  CardTitle,
  CardVideo,
  PodcastCard,
  PodcastCardContent,
  Text,
  Typography,
} from './style';

import { mathIconCategory } from '../../../util/matchAplicationIcons';

import TodayIcon from '@mui/icons-material/Today';
import CategoryIcon from '@mui/icons-material/Category';
import EastIcon from '@mui/icons-material/East';
import { Stack, useMediaQuery, useTheme } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MicIcon from '@mui/icons-material/Mic';
import { DynamicNewsType } from '../../../routes/DynamicNews';

interface ICardNewsMaterial {
  item: Omit<MaterialItem, 'handleClick'>;
  index: number;
}

export const SimilarDynamicNewsContainer: FC<DynamicNewsType> = ({
  newsType,
}) => {
  const [newsList, setNewsList] = useState<Omit<MaterialItem, 'handleClick'>[]>(
    []
  );

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  const [retryCount, setRetryCount] = useState(0);
  const maxRetries = 3;

  const getNewsServer = useCallback(async () => {
    try {
      const result = await getSimilarNews(newsType);
      if (result.length > 0) {
        setNewsList(result);
      } else {
        if (retryCount < maxRetries) {
          setRetryCount((prev) => prev + 1);
        }
      }
    } catch (error) {
      console.error('Erro ao buscar notícias:', error);
      if (retryCount < maxRetries) {
        setRetryCount((prev) => prev + 1);
      }
    }
  }, [newsType, retryCount, maxRetries]);

  useEffect(() => {
    if (retryCount <= maxRetries) {
      getNewsServer();
    } else {
      console.error('getNewsServer error!');
    }
  }, [getNewsServer, retryCount]);

  const CardNewsMaterial = ({ item, index }: ICardNewsMaterial) => {
    return (
      <Card
        sx={{ minWidth: 275 }}
        style={{
          margin: index === 0 && !isMobile ? '0 30px 0 0' : '0 0 30px 0',
        }}
      >
        <CardContentDynamicNews>
          <CardContainerImg>
            <img src={item.image} alt={item.imageAlt} />
          </CardContainerImg>
          <CardContainerInfo>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 1, sm: 2, md: 4 }}
            >
              <CardIconsInfo>
                <TodayIcon />
                <Typography variant="body2">{item.date}</Typography>
              </CardIconsInfo>
              <CardIconsInfo>
                {mathIconCategory[item.category] || <CategoryIcon />}
                <Typography variant="body2">{item.category}</Typography>
              </CardIconsInfo>
            </Stack>

            <CardTitle>{item.title}</CardTitle>

            <CardText>{item.text}</CardText>

            <ActionsInCard>
              <Button size="small" variant="contained">
                Ler completa
                <EastIcon />
              </Button>
            </ActionsInCard>
          </CardContainerInfo>
        </CardContentDynamicNews>
      </Card>
    );
  };

  const CardNewsVideo = ({ item, index }: ICardNewsMaterial) => {
    return (
      <CardVideo
        style={{
          margin: index === 0 && !isMobile ? '0 30px 0 0' : '0 0 30px 0',
        }}
      >
        <CardContentDynamicNews>
          <BoxImage>
            <img src={item.image} alt={item.imageAlt} />
          </BoxImage>
          <BoxText>
            <BoxCardIconInfo>
              <CardIconInfo>
                <TodayIcon />
                <Typography variant="body2">{item.date}</Typography>
              </CardIconInfo>
              <CardIconInfo>
                {mathIconCategory[item.category] || <CategoryIcon />}
                <Typography variant="body2">{item.category}</Typography>
              </CardIconInfo>
            </BoxCardIconInfo>

            <Text>{item.title}</Text>
          </BoxText>
          <BoxChevronCard>
            <ChevronRightIcon />
          </BoxChevronCard>
        </CardContentDynamicNews>
      </CardVideo>
    );
  };

  const CardNewsPodcast = ({ item, index }: ICardNewsMaterial) => {
    return (
      <PodcastCard
        style={{
          display: 'flex',
          minHeight: '180px',
          margin: index === 0 && !isMobile ? '0 30px 0 0' : '0 0 30px 0',
        }}
      >
        <PodcastCardContent>
          <BoxPodcastIcon>
            <MicIcon />
          </BoxPodcastIcon>
          <BoxText>
            <BoxCardIconInfo>
              <CardIconInfo>
                <TodayIcon />
                <p>{item.date}</p>
              </CardIconInfo>
              <CardIconInfo>
                {mathIconCategory[item.category] || <CategoryIcon />}
                <p>{item.category}</p>
              </CardIconInfo>
            </BoxCardIconInfo>
            <Text>{item.title}</Text>
          </BoxText>
          <BoxChevronCard>
            <ChevronRightIcon />
          </BoxChevronCard>
        </PodcastCardContent>
      </PodcastCard>
    );
  };

  const similarDynamicNewsProps = {
    similarNewsList: newsList,
    newsType,
    CardNewsVideo,
    CardNewsPodcast,
    CardNewsMaterial,
  };

  return <SimilarDynamicNews {...similarDynamicNewsProps} />;
};
