import { styled } from '@mui/material';

import MuiBox from '@mui/material/Box';

export const HighlightSpan = styled(MuiBox)({
  textAlign: 'center',
  fontFamily: 'Roboto Serif',
  fontWeight: 500,
  fontSize: 36,
  lineHeight: '36px',
  letterSpacing: 1,
  color: 'var(--xvia-color-grey-100)',
  marginTop: 24,
});
