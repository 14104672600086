import { styled } from '@mui/material';
import MuiBox from '@mui/material/Box';

import { NewsCard as NewsCardComponent } from '../../../../../components/cards/news-card';

export type ContainerCardsProps = {
  size: number;
};

export const ContainerCards = styled(MuiBox)<ContainerCardsProps>(
  ({ theme, size }) => ({
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    flexWrap: 'wrap',

    [theme.breakpoints.up('lg')]: {
      maxHeight: 500 * Math.ceil(size / 4),
    },
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'row',
    },
  })
);

export const BiggerCard = styled(NewsCardComponent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: `calc(50% - ${theme.spacing(1.5)})`,
  boxShadow:
    '4px 4px 8px 0 rgba(0, 0, 0, 0.04), 0px -6px 11px 0 rgba(0, 0, 0, 0.02)',

  [theme.breakpoints.down('lg')]: {
    width: '100%',
    padding: 0,
  },

  '& img': {
    objectFit: 'cover',
    width: '100%',
    height: 287,
  },

  '> .MuiBox-root': {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(2.25),
  },

  '& .CardNotice': {
    height: 'auto',
  },
}));
